<template>
  <div class="flex items-center justify-between pb-[2.4rem] md:border-b-white md:border-b md:border-solid">
    <h2 class="font-medium text-black md:text-[2.4rem] sm:text-[1.8rem]">{{$t('Data regarding the appointment')}}</h2>
    <p class="text-black md:text-[1.6rem] sm:text-[1.8rem]">{{ step }}/3</p>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: 'AppointmentTitle',
  setup() {
    const route = useRoute();
    const step = computed(() => parseInt(route.path.split('/').pop(), 10));

    return {
      step
    };
  }
}
</script>

<style scoped>

</style>