<template>

  <BaseAllArticles
    :title="$t('Related post on') + ' ' + category"
    :link="`/blogs/${category}`"
  >
      
		<SliderComponent
			:arrayData=relatedPosts
			type="one"
		/>

  </BaseAllArticles>
</template>

<script>

import { defineComponent, onMounted } from 'vue';
import BaseAllArticles from '@/components/blog/articles/baseAllArticles.vue';
import SliderComponent from '@/components/blog/slider/sliderComponent.vue';

export default {
  name: 'RelatedPostSection',
  components:{
    BaseAllArticles,
		SliderComponent
  },
  props: {
    relatedPosts: {
      type: Array,
      required: true
    },
    category: {
      type: String,
      required: true
    }
  },
}
</script>

<style scoped>


.related_gap {
	--bs-gutter-x: 3.5rem;
}


.relatedFoodSlider .our_blog_card {
	@apply w-full m-0;
}

.owl-item.active .our_blog_card {
  @apply m-0;
}
.owl-item.active + .owl-item .our_blog_card {
  @apply ml-[3.2rem];
}
.center .our_blog_card {
  @apply w-full;
}

</style>
