<template>
  <div class="container mx-auto">

    <ViewAll 
      :title="title" 
      :link="link" 
    />

    <slot></slot>
  </div>
</template>

<script>

import ViewAll from '@/components/buttons/viewAll.vue';

export default {
  name: 'BaseAllArticles',
  components: {
    ViewAll
  },
  props: {
    title: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped></style>