<template>
  <div class="relative w-full">
    <select class="w-full px-[1.6rem] py-[1.2rem] rounded-[10rem] border border-white text-center appearance-none pr-10 focus:outline-none">
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        >
        {{ option.label }}
      </option>
    </select>
    <div class="pointer-events-none absolute inset-y-0 right-4 flex items-center">
      <svg class="w-4 h-4 fill-current text-gray-600" viewBox="0 0 20 20">
        <path d="M5.5 7l4.5 4.5L14.5 7" />
      </svg>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SelectInput',
    props: {
      options: {
        type: Array,
        required: true,
        validator(value) {
          return value.every(
            option => 'value' in option && 'label' in option
          );
        }
      }
    }
  }
</script>

<style scoped>

</style>
