<template>

	<BaseAccount
		:title="$t('Past Appointments')"
		:appointments=appointments
	>
		<template v-slot:doctorSlot="doctorProps">
        <DoctorComponent
          :doctor="doctorProps.doctor"
          :showServiceTime="true"
          :showPersonalizedService="false"
          :showReviewButton="true"
          @review-appointment="openReviewModal"
        />
		</template>

	</BaseAccount>

  <ReviewPopup
    v-if="isReviewModalVisible"
    id="reviewPopup"
    :doctor="selectedDoctor"
    @close="closeCancelModal"
  />
</template>

<script>

import { defineComponent } from "vue";
import DoctorComponent from '@/components/doctor/doctorComponent.vue';
import ReviewPopup from '@/components/doctor/reviewPopup.vue';
import BaseAccount from '@/components/account/baseAccount.vue';
import axios from 'axios';

export default defineComponent({
  name: "AccountSettingsView",
  components: {
    DoctorComponent,
    ReviewPopup,
		BaseAccount
  },
  data() {
    return {
      isReviewModalVisible: false,
      appointments: null,
    selectedDoctor: null,
    }
  },
	mounted() {
		this.loadAppointments();
	},
  methods: {
    openReviewModal(doctor) {
      this.selectedDoctor = doctor;
      this.isReviewModalVisible = true;
    },
    closeCancelModal() {
      this.isReviewModalVisible = false;
    },
		async loadAppointments() {
			try {
        const id = this.$route.params.id;
				var appointments = await axios.get('https://api.fernandosilva.es/api/medkus/account/appointments/past');
				this.appointments = appointments.data
			} catch (error) {
				console.error('Error fetching featured appointments:', error);
			}
		}
  }});
</script>
