<template>
  <main>
    <div
      class="container mx-auto sm:px-4 mt-[-9rem] rounded-[2.4rem] pt-48"
    >
      <ArticlesPagination
        :categorySlug="categorySlug"
        :category="category"
        :title="title"
      />
    </div>

    <ArticlesHeroSection
      class="pb-[50px] md:pb-[12rem]"
      :category="category"
      :categorySlug="category"
      :title="title"
      :bannerImage="bannerImage"
      :doctorImage="doctorImage"
      :doctorName="doctorName"
    />

    <div v-for="(section, index) in sections" :key="index" class="pb-[50px] md:pb-[12rem]">
      <TextImageSection
        :title="section.title"
        :description="section.description"
        :imageSrc="section.imageSrc"
        :imageAlt="section.imageAlt"
        :useAlternateLayout="section.useAlternateLayout || false"
        :reverseOrder="section.reverseOrder || false"
      />
    </div>

    <RelatedPostSection :relatedPosts="relatedPosts" :category="category" />

  </main>
</template>

<script>
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import ArticlesHeroSection from "@/components/blog/articlesHeroSection.vue";
import TextImageSection from "@/components/blog/textImageSection/textImageSection.vue";
import RelatedPostSection from '@/components/blog/relatedPostSection.vue';
import ArticlesPagination from '@/components/blog/articlesPagination.vue';

export default defineComponent({
  name: "BlogPostView",
  components: {
    ArticlesHeroSection,
    TextImageSection,
    RelatedPostSection,
    ArticlesPagination
  },
  data() {
    return {
      category: "ge",
      title: "10 benefits of dried citrics",
      bannerImage: require("@/assets/images/articles-bannar-image1.png"),
      doctorImage: require("@/assets/images/blog-dr-image1.png"),
      doctorName: "DR. Enrique Centeriuolo",
      sections: [
        {
          slug:"A-Potent-Punch-for-Your-Health",
          title: 'A Potent Punch for Your Health',
          description: 'While fresh citrus fruits are a refreshing summertime treat, dried citrus peels offer a concentrated punch of nutrients and flavor that can be enjoyed year-round. These dehydrated gems are not only a delicious addition to sweet and savory dishes, but they\'re also packed with health benefits. Let\'s explore 10 reasons to incorporate dried citrus into your diet',
          imageSrc: require('@/assets/images/articles-image2.png'),
          imageAlt: 'Dried citrus peels'
        },
        {
          slug:"A-Potent-Punch-for-Your-Health",
          title: 'Antioxidant Bonanza',
          description: 'The drying process intensifies the vitamins and minerals found in fresh citrus. They become excellent sources of vitamin C, a key nutrient for immunity and collagen production.',
          imageSrc: require('@/assets/images/articles-bannar-image2.png'),
          imageAlt: 'Dried citrus peels',
          useAlternateLayout: true
        },
        {
          title: 'Antioxidant Bonanza',
          description: 'The drying process intensifies the vitamins and minerals found in fresh citrus. They become excellent sources of vitamin C, a key nutrient for immunity and collagen production.',
          imageSrc: require('@/assets/images/articles-image3.png'),
          imageAlt: 'Dried citrus peels',
          reverseOrder: true
        },
        {
          title: 'Antioxidant Bonanza',
          description: 'The drying process intensifies the vitamins and minerals found in fresh citrus. They become excellent sources of vitamin C, a key nutrient for immunity and collagen production.',
          imageSrc: require('@/assets/images/articles-image4.png'),
          imageAlt: 'Dried citrus peels'
        }
      ],
      relatedPosts: [
        {
          slug:"A-Potent-Punch-for-Your-Health",
          image: require("@/assets/images/articles-image5.png"),
          doctorImage: require("@/assets/images/blog-dr-image1.png"),
          doctorName: "DR. Enrique Centeriuolo",
          date: "24 may 2024",
          title: "10 way to reduce back pain",
          description: "Explora la tuberculosis en profundidad. Identifica síntomas, explora causas y descubre opciones de tratamiento para comprender y gestionar esta enfermedad..."
        },
        {
          image: require("@/assets/images/articles-image6.png"),
          doctorImage: require("@/assets/images/blog-dr-image1.png"),
          doctorName: "DR. Enrique Centeriuolo",
          date: "24 may 2024",
          title: "10 way to reduce back pain",
          description: "Explora la tuberculosis en profundidad. Identifica síntomas, explora causas y descubre opciones de tratamiento para comprender y gestionar esta enfermedad..."
        },
        {
          image: require("@/assets/images/articles-image5.png"),
          doctorImage: require("@/assets/images/blog-dr-image1.png"),
          doctorName: "DR. Enrique Centeriuolo",
          date: "24 may 2024",
          title: "10 way to reduce back pain",
          description: "Explora la tuberculosis en profundidad. Identifica síntomas, explora causas y descubre opciones de tratamiento para comprender y gestionar esta enfermedad..."
        },
        {
          image: require("@/assets/images/articles-image6.png"),
          doctorImage: require("@/assets/images/blog-dr-image1.png"),
          doctorName: "DR. Enrique Centeriuolo",
          date: "24 may 2024",
          title: "10 way to reduce back pain",
          description: "Explora la tuberculosis en profundidad. Identifica síntomas, explora causas y descubre opciones de tratamiento para comprender y gestionar esta enfermedad..."
        },
        {
          image: require("@/assets/images/articles-image5.png"),
          doctorImage: require("@/assets/images/blog-dr-image1.png"),
          doctorName: "DR. Enrique Centeriuolo",
          date: "24 may 2024",
          title: "10 way to reduce back pain",
          description: "Explora la tuberculosis en profundidad. Identifica síntomas, explora causas y descubre opciones de tratamiento para comprender y gestionar esta enfermedad..."
        },
        {
          image: require("@/assets/images/articles-image6.png"),
          doctorImage: require("@/assets/images/blog-dr-image1.png"),
          doctorName: "DR. Enrique Centeriuolo",
          date: "24 may 2024",
          title: "10 way to reduce back pain",
          description: "Explora la tuberculosis en profundidad. Identifica síntomas, explora causas y descubre opciones de tratamiento para comprender y gestionar esta enfermedad..."
        },
      ]
    };
  },


  mounted() {
    const route = useRoute()
    this.category = route.params.categorySlug
  },
});
</script>


<style scoped>



</style>
