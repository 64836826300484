<template>

	<MainContent class="mt-[1.8rem] px-[4.8rem] py-[2.4rem] sm:px-[1.6rem] sm:py-[3.2rem] sm:mt-0">

		<h3 class="text-black md:text-[2.4rem] capitalize sm:text-[2.5rem] sm:font-normal sm:pb-[1.6rem]">{{ title }}</h3>

    <SubContent
			v-for="(appointment, index) in appointments"
      :key="index"
			class="md:mt-[3.2rem] sm:mb-[3.5rem]"
    >
			<slot
				:doctor="appointment.doctor"
				name="doctorSlot">
			</slot>
    </SubContent>

		<slot></slot>

  </MainContent>

</template>

<script>
import { defineComponent } from "vue";
import MainContent from '@/components/design/mainContent.vue';
import SubContent from '@/components/design/subContent.vue';

export default defineComponent({
  name: "BaseAccount",
  props: {
    title: {
      type: String,
      required: true
    },
    appointments: {
      type: Array,
      required: true
    }
  },
	components: {
		MainContent,
		SubContent
	}
});
</script>

<style scoped></style>
