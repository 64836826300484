<template>
  <main>
    <div
      class="container mx-auto sm:px-4 bg-cover bg-no-repeat bg-center relative z-0 mt-[-9rem] rounded-[2.4rem] pt-48"
    >
      <ArticlesPagination :categorySlug="category" :category="category" />
    </div>

    <TopThisWeek :articles="topArticlesData"/>

    <XAllArticles :articlesData="articles" :category="category" class="sm:mt-[5.0rem]"/>

  </main>
</template>

<script>

import { defineComponent } from "vue";
import { useRoute } from 'vue-router';
import TopThisWeek from "@/components/blog/topThisWeek.vue";
import XAllArticles from "@/components/blog/xAllArticles.vue";
import ArticlesPagination from '@/components/blog/articlesPagination.vue';

export default defineComponent({
  name: "CategorieView",
  components: {
    TopThisWeek,
    XAllArticles,
    ArticlesPagination,
  },


  data() {
    return {
      category: 'ffff',
      topArticlesData: [
        {
          link: '#',
          image: require('@/assets/images/food-image3.png'),
          alt: '',
          title: 'Roasted Potato’s and how...',
          authorImage: require('@/assets/images/articles-dr-enrique.png'),
          authorName: 'DR. Enrique Centeriuolo',
          category: 'Top'
        },
        {
          link: '#',
          image: require('@/assets/images/food-image4.png'),
          alt: '',
          title: 'Roasted Potato’s and how...',
          authorImage: require('@/assets/images/articles-dr-enrique.png'),
          authorName: 'DR. Enrique Centeriuolo',
          category: 'Top'
        },
        {
          link: '#',
          image: require('@/assets/images/food-image5.png'),
          alt: '',
          title: 'Roasted Potato’s and how...',
          authorImage: require('@/assets/images/articles-dr-enrique.png'),
          authorName: 'DR. Enrique Centeriuolo',
          category: 'Top'
        }
      ],
      articles: [
          {
            link: '#',
            image: require('@/assets/images/food-image1.png'),
            alt: '',
            title: '1 Roasted Potato’s and how...',
            authorImage: require('@/assets/images/articles-dr-enrique.png'),
            authorName: 'DR. Enrique Centeriuolo',
            category: this.getCategory()
          },
          {
            link: '#',
            image: require('@/assets/images/food-image2.png'),
            alt: '',
            title: '2 Roasted Potato’s and how...',
            authorImage: require('@/assets/images/articles-dr-enrique.png'),
            authorName: 'DR. Enrique Centeriuolo',
            category: this.getCategory()
          },
          {
            link: '#',
            image: require('@/assets/images/food-image3.png'),
            alt: '',
            title: '3 Roasted Potato’s and how...',
            authorImage: require('@/assets/images/articles-dr-enrique.png'),
            authorName: 'DR. Enrique Centeriuolo',
            category: this.getCategory()
          },
          {
            link: '#',
            image: require('@/assets/images/food-image4.png'),
            alt: '',
            title: '4 Roasted Potato’s and how...',
            authorImage: require('@/assets/images/articles-dr-enrique.png'),
            authorName: 'DR. Enrique Centeriuolo',
            category: this.getCategory()
          },
          {
            link: '#',
            image: require('@/assets/images/food-image5.png'),
            alt: '',
            title: '5 Roasted Potato’s and how...',
            authorImage: require('@/assets/images/articles-dr-enrique.png'),
            authorName: 'DR. Enrique Centeriuolo',
            category: this.getCategory()
          },
          {
            link: '#',
            image: require('@/assets/images/food-image2.png'),
            alt: '',
            title: '2 Roasted Potato’s and how...',
            authorImage: require('@/assets/images/articles-dr-enrique.png'),
            authorName: 'DR. Enrique Centeriuolo',
            category: this.getCategory()
          },
          {
            link: '#',
            image: require('@/assets/images/food-image1.png'),
            alt: '',
            title: '1 Roasted Potato’s and how...',
            authorImage: require('@/assets/images/articles-dr-enrique.png'),
            authorName: 'DR. Enrique Centeriuolo',
            category: this.getCategory()
          },
          {
            link: '#',
            image: require('@/assets/images/food-image1.png'),
            alt: '',
            title: '1 Roasted Potato’s and how...',
            authorImage: require('@/assets/images/articles-dr-enrique.png'),
            authorName: 'DR. Enrique Centeriuolo',
            category: this.getCategory()
          },
          {
            link: '#',
            image: require('@/assets/images/food-image4.png'),
            alt: '',
            title: '4 Roasted Potato’s and how...',
            authorImage: require('@/assets/images/articles-dr-enrique.png'),
            authorName: 'DR. Enrique Centeriuolo',
            category: this.getCategory()
          },
          {
            link: '#',
            image: require('@/assets/images/food-image3.png'),
            alt: '',
            title: '3 Roasted Potato’s and how...',
            authorImage: require('@/assets/images/articles-dr-enrique.png'),
            authorName: 'DR. Enrique Centeriuolo',
            category: this.getCategory()
          },
          {
            link: '#',
            image: require('@/assets/images/food-image4.png'),
            alt: '',
            title: '4 Roasted Potato’s and how...',
            authorImage: require('@/assets/images/articles-dr-enrique.png'),
            authorName: 'DR. Enrique Centeriuolo',
            category: this.getCategory()
          },
          {
            link: '#',
            image: require('@/assets/images/food-image5.png'),
            alt: '',
            title: '5 Roasted Potato’s and how...',
            authorImage: require('@/assets/images/articles-dr-enrique.png'),
            authorName: 'DR. Enrique Centeriuolo',
            category: this.getCategory()
          },
      ]
    }
  },
  mounted() {
    const route = useRoute()
    this.category = route.params.categorySlug
  },
  methods: {
    getCategory() {
      const route = useRoute();
      return route.params.categorySlug;
    }
  }
});
</script>
