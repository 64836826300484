<template>
  <button class="px-[3.2rem] py-[1.2rem] rounded-[10rem] border border-white">
		<slot></slot>
  </button>
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseButton',
  props: {

  }
});
</script>

<style scoped>
.button {
}
</style>
