
export const MAP_URL = "http://www.google.com/maps/place/";

export const GlobalErrors = { ERROR_400: 400, ERROR_500: 500, ERROR_PRIVATE_CLUB: 424, ERROR_402: 402 };

export const NetworkError = {
  ABORTED: "ECONNABORTED",
  NET: "Network Error",
  PROXY: "Proxy Error"
};


