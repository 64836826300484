<template>

      <div class="w-[100%] related_gap pt_35">
          <carousel class="item center" :items-to-show="itemsToShow" :breakpoints="breakpoints" :snapAlign="snapAlign" wrapAround="true">
            <slide class="item" v-for="(post, slide) in posts" :key="slide">
              <SubContent paddingClass="w-[17rem] ml-auto mx-[3.2rem] my-0 p-[0.8rem]" class="our_blog_card">
                <BlogCard :blog="post" />
              </SubContent>
            </slide>
          </carousel>
      </div>

</template>

<script>

import { defineComponent, onMounted } from 'vue';
import SubContent from '@/components/design/subContent.vue';
import BlogCard from '@/components/blog/post/blogCard.vue';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

export default {
  name: 'SliderOne',
  components:{
    SubContent,
    BlogCard,
    Carousel,
    Slide,
  },
  props: {
    posts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      itemsToShow: 3,
      snapAlign: 'start',
      breakpoints: {
        0: {
          itemsToShow: 1.2,
        },
        1024: {
          itemsToShow: 3,
        },
      },
    }
  },
}
</script>

<style scoped>


.related_gap {
	--bs-gutter-x: 3.5rem;
}


.relatedFoodSlider .our_blog_card {
	@apply w-full m-0;
}

.owl-item.active .our_blog_card {
  @apply m-0;
}
.owl-item.active + .owl-item .our_blog_card {
  @apply ml-[3.2rem];
}
.center .our_blog_card {
  @apply w-full;
}

</style>
