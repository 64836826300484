<template>
  <div class="md:pt-48 sm:pt-[5.0rem]">
    <div class="container mx-auto sm:px-4">
      <ViewAll :link="`/search/`" :title="$t('Outstanding doctors')" />

      <div class="pt-[1.6rem]">
        <carousel :items-to-show="itemsToShow" :breakpoints="breakpoints" :snapAlign="snapAlign">
          <slide class="item" v-for="(doctor, slide) in doctors" :key="slide">
            <DoctorInformation
              class="item w-full mx-2"
              :doctor="doctor"
              :homeDesign="true"
            />
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent, onMounted } from 'vue';
import DoctorInformation from '@/components/doctor/doctorInformation.vue';
import ViewAll from '@/components/buttons/viewAll.vue';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'
import axios from 'axios';

export default defineComponent({
  name: 'FeaturedDoctorsSection',
  components: {
    DoctorInformation,
    ViewAll,
    Carousel,
    Slide
  },
  data() {
    return {
      itemsToShow: 3,
      breakpoints: {
        0: {
          itemsToShow: 1,
        },
        1024: {
          itemsToShow: 3,
        },
      },
      doctors: [
      ]
    }
  },

	mounted() {
		this.getFeaturedDoctors();
	},

	methods: {
		async getFeaturedDoctors() {
			try {
				// Make the GET request to the API endpoint
				var doctors = await axios.get('https://api.fernandosilva.es/api/medkus/featured-doctors');
				this.doctors = doctors.data
			} catch (error) {
				console.error('Error fetching featured doctors:', error);
			}
		}
	}

});
</script>

<style scoped></style>
