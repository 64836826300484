<template>
  <BaseButton v-if="!isCancel && !isCancelPrimary" type="submit" class="bg-black text-white hover:bg-[rgb(40,40,40)] hover:text-white text-center">
    {{ label }}
  </BaseButton>
  <BaseButton v-else-if="isCancel" type="submit" class="bg-white rounded-[10rem] hover:underline text-[#A70D00] transition-[0.3s] duration-[ease] hover:bg-[rgb(40,40,40)] hover:text-white text-center">
    {{ label }}
  </BaseButton>

  <button v-else type="submit" class="text-[#A70D00] font-medium hover:text-[#A70D00] hover:underline">
    {{ label }}
  </button>

</template>

<script>

import { defineComponent } from 'vue';
import BaseButton from '@/components/buttons/baseButton.vue';

export default defineComponent({
  name: 'CustomButton',
	components: {
		BaseButton,
	},
  props: {
    label: {
      type: String,
      required: true
    },
    isCancel: {
      type: Boolean,
      required: false,
      default: false
    },
    isCancelPrimary: {
      type: Boolean,
      required: false,
      default: false
    }
  }
});
</script>

<style scoped>
.button {
}
</style>
