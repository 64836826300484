import { createStore } from 'vuex';

const loginIsOpen = false;
const loginRedirect = "";
const globalInfoIsOpen = false;
const globalErrorIsOpen = false;
const loading = false;
const i18nLocale = '';


export default createStore({
  state: {
    loading,
    i18nLocale,
    loginIsOpen,
    loginRedirect,
    globalInfoIsOpen,
    globalErrorIsOpen,
    usuallyPlaySelected: 0,
    searchDateSelected: null,
    searchTimeSelected: null,
    fullDateTime: Date,
    searchIsFlexible: true,
    tenantInfo: {},
    globalErrorData: {},
    globalErrorRedirectUrl: null,
    globalErrorOnlyPopup: null,
    getInChatConversations: false,
  },
  mutations: {
    openLogin(state: any, payload: any) {
      state.loginIsOpen = true;
      if (payload !== undefined) {
        state.loginRedirect = payload.goToUrl;
        state.allowClosePopup = payload === undefined || payload.allowClose === undefined ? false : payload.allowClose;
      }
    },
    closeLogin(state: any) {
      state.loginIsOpen = false;
      state.user = JSON.parse(localStorage.getItem('user')!);
    },

    openRegister(state: any, payload: any) {
      state.registerIsOpen = true;
      state.allowClosePopup = payload === undefined || payload.allowClose === undefined ? false : payload.allowClose;
    },
    closeRegister(state: any) {
      state.registerIsOpen = false;
    },

    openGlobalInfo(state: any) {
      state.globalInfoIsOpen = true;
    },
    closeGlobalInfo(state: any) {
      state.globalInfoIsOpen = false;
    },

    setI18nLocale(state, payload) {
      state.i18nLocale = payload;
    },

    openGlobalError(state: any) {
      state.globalErrorIsOpen = true;
    },
    closeGlobalError(state: any) {
      state.globalErrorIsOpen = false;
    },
    setGlobalErrorData(state, data) {
      state.globalErrorData = data;
    },
    setGlobalErrorRedirectUrl(state, data) {
      state.globalErrorRedirectUrl = data;
    },

    setLoading(state: any, data) {
      state.loading = data;
    },
    setGlobalErrorCode(state, data) {
      state.globalErrorCode = data;
    }

  },
  actions: {
    openLogin: ({ commit }) => commit('openLogin'),
    closeLogin: ({ commit }) => commit('closeLogin'),
    openRegister: ({ commit }) => commit('openRegister'),
    closeRegister: ({ commit }) => commit('closeRegister'),
    openGlobalInfo: ({ commit }) => commit('openGlobalInfo'),
    closeGlobalInfo: ({ commit }) => commit('closeGlobalInfo'),
    openGlobalError: ({ commit }) => commit('openGlobalError'),
    closeGlobalError: ({ commit }) => commit('closeGlobalError'),
    setLoading: ({ commit }) => commit('setLoading'),
  },
  modules: {
  }
});
