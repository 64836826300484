<template>
  <div>
    <label 
      v-if="label || $slots.default" 
			:class="[labelClass, {'checkbox-label': inputType === 'checkbox', 'checked': isChecked}, checkboxClass]"
      @click="toggleCheckbox"
    >
      <span v-if="label" v-html="label"></span>
      <slot v-else></slot>
    </label>
    <input
      v-if="inputType !== 'checkbox'"
      :type="inputType"
      :class="[inputClass, customInputClass]"
      :placeholder="placeholder"
      :value="modelValue"
      @input="updateValue"
    />
    <input
      v-else
      type="checkbox"
      :class="[inputClass]"
      :checked="isChecked"
      @change="updateValue"
      style="display: none"
    />

		<a v-if="infoInInput" href="#" class="text-end float-right sticky mt-[-3.2rem] text-black underline mr-[1.6rem]">{{infoInInput}}</a>

    <span v-if="isInvalid" class="text-[red] text-[0.75em]">{{$t('This field is mandatory')}}</span>

  </div>
</template>

<script>
export default {
  name: 'InputField',
  props: {
    inputType: {
      type: String,
      default: 'text'
    },
    inputClass: {
      type: String,
      default: 'w-full h-[4.8rem] px-[1.6rem] py-0 rounded-[10rem] border border-white'
    },
    customInputClass: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: 'text-black font-bold pb-4'      
    },
    placeholder: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Boolean],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
		infoInInput: {
      type: String,
      default: ""
		}
  },
  data() {
    return {
      isInvalid: false,
      isChecked: this.modelValue
    }
  },
	computed: {
    checkboxClass() {
      if (this.inputType === 'checkbox') {
        return 'block relative font-normal text-[1.4rem] cursor-pointer select-none pl-[3.1rem] before:content-[""] before:absolute before:w-[1.8rem] before:h-[1.8rem] before:border before:rounded-[50%] before:border-solid before:border-black before:left-0 before:top-0';
      }
      return '';
    },
	},
  methods: {
    updateValue(event) {
      if (this.inputType === 'checkbox') {
        this.isChecked = event.target.checked;
        this.$emit('update:modelValue', this.isChecked);
        this.isInvalid = this.required && !this.isChecked;
      } else {
        const value = event.target.value.trim();
        this.$emit('update:modelValue', value);
        this.isInvalid = this.required && !value;
      }
    },
    toggleCheckbox() {
      if (this.inputType === 'checkbox') {
        this.isChecked = !this.isChecked;
        this.$emit('update:modelValue', this.isChecked);
        this.isInvalid = this.required && !this.isChecked;
      }
    }
  },
  watch: {
    modelValue(newVal) {
      if (this.inputType === 'checkbox') {
        this.isChecked = newVal;
        this.isInvalid = this.required && !this.isChecked;
      } else {
        this.isInvalid = this.required && !newVal.trim();
      }
    }
  }
}
</script>

<style scoped>
.input.is-invalid {
  border-color: red;
}

.checkbox-label {
  position: relative;
  cursor: pointer;
  padding-left: 25px;
}

.checkbox-label.checked::before {
  background: url('@/assets/images/tic_mark.svg') no-repeat center;
}

.checkbox-label.is-invalid {
  color: red;
}

.checkbox-label.is-invalid::before {
  border: 1px solid #f00 !important;
}
</style>
