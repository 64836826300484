<template>
  <div class="footer_menu" v-for="(menu, index) in footerMenus" :key="index">
    <ul class="menu_list">
      <li class="inline-block px-[0.9rem] py-0 border-r-[#D9D9D9] border-r border-solid last:border-[none]" v-for="(item, itemIndex) in menu" :key="itemIndex">
        <a class="" :href="item.link">{{ item.text }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'FooterMenu',
  props: {
    footerMenus: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

.menu_list .active {
	@apply underline;
}
.menu_list li a {
	@apply text-[#1B2734] hover:opacity-60 font-jost;
}

.footer_menu {
	@apply bg-white-50 overflow-auto mb-[2.4rem] px-0 py-[2.3rem] rounded-[1.2rem] border border-white;
}
.footer_menu .menu_list {
	@apply flex overflow-auto px-[2.3rem] py-0;
	scrollbar-width: none;
}
.footer_menu .menu_list li a {
	@apply whitespace-nowrap;
}
.footer_menu .menu_list::-webkit-scrollbar {
	@apply hidden w-0;
}

</style>
