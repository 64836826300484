<template>
  <BaseButton class="bg-white inline-flex justify-center items-center" @click="toggleCheck">
    <span class="text-black">{{$t('More filters')}}</span>
    <img class="w-[2.4rem]" src="@/assets/images/filters_icon.svg" alt="">
  </BaseButton>
</template>

<script>

import { defineComponent } from 'vue';
import BaseButton from '@/components/buttons/baseButton.vue';

export default defineComponent({
  name: 'InPersonButton',
  components: {
    BaseButton
  },
});
</script>

<style scoped></style>
