<template>
  <main>
    <div class="pb-[4.8rem] container mx-auto sm:px-4" v-if="doctor">

          <div class="md:flex md:flex-wrap mt-8">

            <div class="md:w-2/3 pr-4 pl-4 sm:mb-[15px] md:mb-[0px]">
              <MainContent class="sticky top-48">
                <div class="md:flex gap-4 pb-4">
                  <div class="md:w-[61%] sm:mb-[15px] md:mb-[0px]">
                    <SubContent paddingClass="p-[1.6rem]">
                      <DoctorComponent :doctor="doctor" showAboutMe="true" />
                    </SubContent>
                  </div>
                  <div class="md:w-[39%] sm:mb-[15px] md:mb-[0px]">
                    <div
                      class="bg-white h-full relative p-[1.2rem] rounded-[2.9rem] border border-white w-[29rem] h-[29rem]"
                    >

                      <GoogleMaps v-if="doctor" :doctors="[doctor]" class="w-full h-full" ref="map"/>

                      <button
                        class="w-[21.8rem] text-center bg-white absolute -translate-x-2/4 px-[2.2rem] py-[1.1rem] rounded-[2.9rem] border border-white left-2/4 top-12"
                        @click.prevent="viewMapModal"
                      >
                        <h3 class="text-black text-[1.8rem]">Ampliar mapa</h3>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="sm:mb-[15px] md:mb-[0px]">
                  <SubContent>
                    <DoctorSchedule />
                  </SubContent>
                </div>
              </MainContent>
            </div>

            <div class="md:w-1/3 pr-4 pl-4 sm:mb-[15px] md:mb-[0px]">
              <MainContent>
                <SubContent class="sm:mb-[15px] md:mb-[0px]">
                  <WorkExperience
                    :aboutMe="doctor.aboutMe"
                    :education="doctor.education"
                  />
                </SubContent>

                <SubContent class="mt-4 sm:mb-[15px] md:mb-[0px]">
                  <Services :services="doctor.services" />
                </SubContent>

                <SubContent class="mt-4">
                  <Reviews :reviews="doctor.reviews" :averageRating="5.0" />
                </SubContent>

              </MainContent>
            </div>
        </div>

      <MapModal
        v-if="isMapModalVisible"
        :doctors="[doctor]"
        @close="closeMapModal"
      />
    </div>
  </main>
</template>

<script>
import { useStore } from "vuex";
import { defineComponent } from "vue";
import DoctorComponent from '@/components/doctor/doctorComponent.vue';
import DoctorSchedule from '@/components/search/doctorSchedule.vue';
import WorkExperience from '@/components/doctor/workExperience.vue';
import Services from '@/components/doctor/services.vue';
import Reviews from '@/components/doctor/reviews.vue';
import MapModal from '@/components/search/mapModal.vue';
import MainContent from '@/components/design/mainContent.vue';
import SubContent from '@/components/design/subContent.vue';
import GoogleMaps from '@/components/doctor/googleMaps.vue';
import axios from 'axios';

export default defineComponent({
  name: "ProfileView",
  components: {
    DoctorComponent,
    DoctorSchedule,
    WorkExperience,
    Services,
    Reviews,
    MapModal,
    MainContent,
    SubContent,
    GoogleMaps
  },
  async created() {
    this.store = useStore();
  },
	mounted() {
		this.loadProfile();
	},
  data() {
    return {
      isMapModalVisible : false,
      specialty: 'Fernando',
      insurance: 'Aseguradora',
      doctor: null,
    }
  },
  methods: {
    viewMapModal(doctor) {
      this.selectedDoctor = doctor;
      this.isMapModalVisible = true;
    },
    closeMapModal() {
      this.isMapModalVisible = false;
    },
		async loadProfile() {
			try {
        const id = this.$route.params.id;
				var doctor = await axios.get('https://api.fernandosilva.es/api/medkus/doctors/' + id);
				this.doctor = doctor.data
			} catch (error) {
				console.error('Error fetching featured doctors:', error);
			}
		}
  },
});
</script>

<style scope></style>
