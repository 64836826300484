<template>
  <div class="md:mt-[-11.5rem] sm:pt-[5.0rem] container mx-auto sm:px-4">
      <MainContent paddingClass="p-[2.4rem]">
      
        <h2 class="md:text-[3.2rem] sm:text-[2.4rem] font-semibold text-center text-black">
          {{$t('Customized integrative medicine.')}}
        </h2>

          <div class="md:flex pt-[4.8rem]">
            <div class="pr-4 pl-4 md:w-1/2 sm:pb-5" v-for="box in boxes" :key="box.id">
              <SubContent paddingClass="pt-[3.2rem] pb-[1.6rem] px-[1.6rem]">
                <img class="w-[7.2rem] relative z-[1]" :src="box.icon" alt="icon" />
                <img class="absolute w-full left-0 top-0" src="@/assets/images/medicina-shape.png" alt="shape" />
                <div class="pt-[7.4rem]">
                  <h4 class="text-black text-[1.6rem] font-medium tracking-[-0.48px]">{{ box.title }}</h4>
                  <p class="max-w-[24.6rem] leading-[1.28] pt-4">{{ box.description }}</p>
                </div>
              </SubContent>
            </div>
          </div>
        
      </MainContent>

  </div>
</template>

<script>

import MainContent from '@/components/design/mainContent.vue';
import SubContent from '@/components/design/subContent.vue';

export default {
  name: 'MedicineAboutSection',
  components: {
    MainContent,
    SubContent
  },
  data() {
    return {
      boxes: [
        {
          id: 1,
          icon: require('@/assets/images/icon-1.svg'),
          title: this.$t('Find your specialist'),
          description: this.$t('Real opinions from thousands of patients will help you always make the best decision.')
        },
        {
          id: 2,
          icon: require('@/assets/images/icon-2.svg'),
          title: this.$t('Book an appointment easily'),
          description: this.$t('Choose your preferred time and book an appointment without the need to call. Its easy, convenient, and very fast.')
        },
        {
          id: 3,
          icon: require('@/assets/images/icon-3.svg'),
          title: this.$t('No additional costs'),
          description: this.$t('Appointment booking is a free service provided by Medkus.')
        },
        {
          id: 4,
          icon: require('@/assets/images/icon-4.svg'),
          title: this.$t('SMS reminders'),
          description: this.$t('We confirm your appointment instantly and send you a reminder by SMS before your appointment.')
        }
      ]
    }
  }
}
</script>

<style scoped>

.medicine_icon_box {
	background: #fcfcfc;
}

.medicine_about_section_inner {
	background: rgba(255, 255, 255, 0.5);
}

</style>
