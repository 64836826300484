<template>
    <img :src="blog.image" alt="Blog image" />
    <div class="px-[0.9rem] py-[1.6rem] blog_card_content">
      <DoctorImage 
        :doctorImage="blog.doctorImage"
        :doctorName="blog.doctorName"
        :date="blog.date"
        additionalClass="text-[color:var(--black)]"
      />
      <h4 class="text-left text-black font-medium text-[0.8rem] pt-[0.9rem] pb-[0.4rem] px-0">{{ blog.title }}</h4>
      <p class="text-left">{{ blog.description }}</p>

      <CustomButton class="sm:w-full mt-10"
        :label="blog.buttonText || $t('Read article')"
      />

    </div>
</template>

<script>

import DoctorImage from '@/components/blog/articles/doctorImage.vue';
import CustomButton from '@/components/buttons/customButton.vue';

export default {
  name: 'BlogCard',
  components: {
    DoctorImage,
    CustomButton
  },
  props: {
    blog: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
