<template>
  <GMapMap
    :center="center"
    :zoom="16"
    map-type-id="terrain"
    :options="mapOptions"
  >
    <GMapMarker
      v-for="(doctor, index) in doctors"
      :key="index"
      :position="doctor.position"
      :clickable="true"
      :draggable="true"
      :icon="getMarkerIcon(index)"
      @click="onMarkerClick(index)"
    />
  </GMapMap>
</template>

<script>
export default {
  name: 'GoogleMaps',
  data() {
    return {
      center: null,
      selectedMarkerIndex: null,
      mapOptions: {
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: true,
        minZoom: 8,
        gestureHandling: 'greedy',
      },
    }
  },
  props: {
    doctors: {
      type: Array,
      required: true,
    },
    selectedDoctor: {
      type: Object,
      default: null
    }
  },
  mounted() {
    if (this.doctors.length > 0) {
      this.center = this.doctors[0].position;
    }
  },
  methods: {
    onMarkerClick(index) {
      this.center = this.doctors[index].position;
      
      this.selectedMarkerIndex = index;
      
      const doctorElement = document.getElementById(`doctor-${index}`);
      if (doctorElement) {
        doctorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },

    getMarkerIcon(index) {
      if (index === this.selectedMarkerIndex) {
        return {
          url: require('@/assets/images/pin.svg'),
        };
      } else {
        return {
          url: require('@/assets/images/unpin.svg'),
        };
      }
    }
  }
}
</script>

