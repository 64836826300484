<template>

      <DoctorImage :image="review.image" :name="review.name"/>

      <div>

        <DoctorRating :reviews="[review]" />

        <p class="text-left text-gray-600 font-manrope font-medium leading-tight max-w-sm sm:text-[1.6rem] md:text-[1.6rem]">{{ review.text }}</p>

        <h5 class="text-left text-black text-[1.2rem] sm:text-[1.2rem] font-bold">
          {{ review.name }} <br />
          <span class="text-black text-[1.0rem] font-normal">{{ review.location }}</span>
        </h5>
      </div>

</template>

<script>
import DoctorImage from '@/components/doctor/doctorImage.vue';
import DoctorRating from '@/components/doctor/doctorRating.vue';
export default {
  name: 'ReviewComponent',
  components: {
    DoctorImage,
    DoctorRating
  },
  props: {
    review: {
      type: Array,
      required: true,
      default: () => [],
    }
  },
}
</script>

<style scoped>

.rating_text {
	font-family: Manrope;
}

</style>
