<template>
    <a href="#" class="back-to-top w-[4.5rem] h-[4.5rem] leading-[4.5rem] text-center fixed z-[1000] rounded-[50%] right-8 bottom-12"><img src="@/assets/images/back-top.png" alt="" /></a>
</template>

<script>
export default {
  name: 'BackToTop',
}
</script>


<style scoped>

</style>
