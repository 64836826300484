<template>
  <div class="textarea-field-container">
    <label v-if="label" class="text-black font-bold">{{ label }}</label>
    <textarea
      :class="textareaClass"
      :placeholder="placeholder"
      :value="modelValue"
      @input="updateValue"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: 'TextareaField',
  props: {
    textareaClass: {
      type: String,
      default: 'textarea'
    },
    placeholder: {
      type: String,
      default: 'Puedes incluir cualquier comentario para el especialista'
    },
    modelValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },
  methods: {
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value);
    }
  }
}
</script>

<style scoped>

</style>
