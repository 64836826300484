<template>
  <div class="flex items-center justify-between">
    <h4 class="text-black text-[2.4rem] font-semibold" v-html="title"></h4>
    <a v-if="link" :href="link" class="text-black text-right font-medium underline hover:opacity-60">
      {{$t('View all')}}
    </a>
  </div>
</template>

<script>
export default {
  name: 'ViewAll',
  props: {
    title: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>
</style>
