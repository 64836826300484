<template>

	<BaseAccount
		:title="$t('Next Appointments')"
		:appointments=appointments
	>

		<template v-slot:doctorSlot="doctorProps">
				<DoctorComponent
					:doctor="doctorProps.doctor"
					:showServiceTime="true"
					:showCancelButton="true"
					@cancel-appointment="openCancelModal"
				/>
		</template>

	</BaseAccount>

  <CancelPopup
    v-if="isCancelModalVisible"
    id="cancelQuotePopup"
    :doctor="selectedDoctor"
    :showServiceTime="true"
    :showCancelAppointmentButton="true"
    @close="closeCancelModal"
  />

</template>

<script>
import { defineComponent } from "vue";
import DoctorComponent from '@/components/doctor/doctorComponent.vue';
import CancelPopup from '@/components/doctor/cancelPopup.vue';
import BaseAccount from '@/components/account/baseAccount.vue';
import axios from 'axios';

export default defineComponent({
  name: "AccountSettingsView",
  components: {
    DoctorComponent,
    CancelPopup,
		BaseAccount
  },
  data() {
    return {
      appointments: null,
      selectedDoctor: null,
      isCancelModalVisible: false,
    }
  },
	mounted() {
		this.loadAppointments();
	},
  methods: {
    openCancelModal(doctor) {
      this.selectedDoctor = doctor;
      this.isCancelModalVisible = true;
    },
    closeCancelModal() {
      this.isCancelModalVisible = false;
    },
		async loadAppointments() {
			try {
        const id = this.$route.params.id;
				var appointments = await axios.get('https://api.fernandosilva.es/api/medkus/account/appointments/upcoming');
				this.appointments = appointments.data
			} catch (error) {
				console.error('Error fetching featured appointments:', error);
			}
		}
  }
});
</script>

<style scoped>
</style>
