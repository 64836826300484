<template>
  <span class="flex items-center">
    <div
      v-for="i in 5"
      :key="i"
      class="relative w-6 h-6"
    >
      <svg
        class="absolute inset-0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="white"
        stroke="black"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 17.27l5.18 3.73-1.64-6.81 5.18-4.38-6.88-.59L12 2 9.16 9.22l-6.88.59 5.18 4.38L6.82 21z"
        />
      </svg>

      <svg
        class="absolute inset-0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        :style="{ clipPath: `inset(0 ${getClipPercentage(i)}% 0 0)` }"
        fill="black"
        stroke="black"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 17.27l5.18 3.73-1.64-6.81 5.18-4.38-6.88-.59L12 2 9.16 9.22l-6.88.59 5.18 4.38L6.82 21z"
        />
      </svg>
    </div>
    <span class="text-black md:text-[12px] ml-2">{{ averageRating }} / 5</span>
  </span>
</template>

<script>
export default {
  name: "DoctorRating",
  props: {
    reviews: {
      type: Array,
      required: true,
    },
  },
  computed: {
    averageRating() {
      const reviews = this.reviews || [];
      if (reviews.length === 0) return 0;

      const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
      return (totalRating / reviews.length).toFixed(1);
    },
  },
  methods: {
    getClipPercentage(starIndex) {
      const rating = parseFloat(this.averageRating);
      if (starIndex <= rating) {
        return 0;
      } else if (starIndex - 1 < rating && starIndex > rating) {
        return (1 - (rating % 1)) * 100;
      }
      return 100;
    },
  },
};
</script>

<style scoped>
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
</style>
