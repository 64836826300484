<template>
  <main>

      <div class="container mx-auto sm:px-4">

        <MainContent
          paddingClass="md:pt-28 md:pb-52 md:px-[4.8rem] sm:px-[1.0rem] sm:pb-[5.0rem] sm:pt-[13.5rem]"
        >
          <div>
            <h2
              class="text-black md:text-5xl sm:text-[2.5rem] font-semibold tracking-[1.2px] uppercase"
            >
              {{$t('Knowledge is better when is shared')}}
            </h2>
            <p
              class="text-black text-[1.6rem] font-medium pt-[1.6rem]"
            >
              {{$t('Know more about any topic about healthcare')}}
            </p>
          </div>
          <div class="pt-[6.4rem]">
            <h3
              class="text-[color:var(--black)] text-[1.8rem] font-semibold pb-[2.4rem]"
            >
              {{$t('All articles')}}
            </h3>
            <FooterMenu :footerMenus="footerMenus" />
          </div>
        </MainContent>

      </div>

    <TopThisWeek class="md:pt-48 sm:pt-[5.0rem]" :articles="topArticlesData" />

    <XAllArticles
      class="md:pb-[12rem] sm:mt-14"
      :articlesData="foodArticlesData.articles"
      :category="foodArticlesData.category"
    />

    <CategoryPosts
      v-for="(category, index) in categories"
      :key="category.title"
      :posts="category.posts"
      :categoryTitle="category.title"
      :class="{ 'md:pb-[12rem]': index !== categories.length - 1 }"
    />
  </main>
</template>

<script>
import { defineComponent } from "vue";
import CategoryPosts from "@/components/blog/categoryPosts.vue";
import TopThisWeek from "@/components/blog/topThisWeek.vue";
import XAllArticles from "@/components/blog/xAllArticles.vue";
import FooterMenu from '@/components/home/footerMenu.vue';
import MainContent from '@/components/design/mainContent.vue';

export default defineComponent({
  name: "ArticlesView",
  components: {
    CategoryPosts,
    TopThisWeek,
    XAllArticles,
    FooterMenu,
    MainContent
  },
  data() {
    return {

          footerMenus: [
            [
              { text: 'Psicólogo', link: '#' },
              { text: 'Ginecólogo', link: '#' },
              { text: 'Traumatólogo', link: '#' },
              { text: 'Dermatólogo', link: '#' },
              { text: 'Psiquiatra', link: '#' },
              { text: 'Dentista', link: '#' },
              { text: 'Médico general', link: '#' },
              { text: 'Otorrino', link: '#' },
              { text: 'Oftalmólogo', link: '#' },
              { text: 'Urólogo', link: '#' },
              { text: 'Podólogo', link: '#' },
              { text: 'Alergólogo', link: '#' },
            ]
          ],

      categories: [
        {
          title: 'Healthcare',
          posts: [
            {
              link: '#',
              image: require('@/assets/images/healthcare-image1.png'),
              imageAlt: 'Healthcare Image 1',
              title: 'All benefits on Yoga',
              authorImage: require('@/assets/images/articles-dr-enrique.png'),
              doctorImageAlt: 'Dr. Enrique Centeriuolo',
              authorName: 'By DR. Enrique Centeriuolo',
              category: 'Healthcare'
            },
            {
              link: '#',
              image: require('@/assets/images/healthcare-image2.png'),
              imageAlt: 'Healthcare Image 2',
              title: 'What are those?',
              authorImage: require('@/assets/images/articles-dr-enrique.png'),
              doctorImageAlt: 'Dr. Enrique Centeriuolo',
              authorName: 'By DR. Enrique Centeriuolo',
              category: 'Healthcare'
            },
            {
              link: '#',
              image: require('@/assets/images/healthcare-image3.png'),
              imageAlt: 'Healthcare Image 3',
              title: 'Are you doing well your stretching?',
              authorImage: require('@/assets/images/articles-dr-enrique.png'),
              doctorImageAlt: 'Dr. Enrique Centeriuolo',
              authorName: 'By DR. Enrique Centeriuolo',
              category: 'Healthcare'
            },
            {
              link: '#',
              image: require('@/assets/images/healthcare-image4.png'),
              imageAlt: 'Healthcare Image 4',
              title: 'Best exercise for elder people',
              authorImage: require('@/assets/images/articles-dr-enrique.png'),
              doctorImageAlt: 'Dr. Enrique Centeriuolo',
              authorName: 'By DR. Enrique Centeriuolo',
              category: 'Healthcare'
            }
          ]
        },
        {
          title: 'Sports',
          posts: [
            {
              link: '#',
              image: require('@/assets/images/sports-image1.png'),
              imageAlt: 'Healthcare Image 1',
              title: 'All benefits on Yoga',
              authorImage: require('@/assets/images/articles-dr-enrique.png'),
              doctorImageAlt: 'Dr. Enrique Centeriuolo',
              authorName: 'By DR. Enrique Centeriuolo',
              category: 'Sports'
            },
            {
              link: '#',
              image: require('@/assets/images/sports-image2.png'),
              imageAlt: 'Healthcare Image 2',
              title: 'What are those?',
              authorImage: require('@/assets/images/articles-dr-enrique.png'),
              doctorImageAlt: 'Dr. Enrique Centeriuolo',
              authorName: 'By DR. Enrique Centeriuolo',
              category: 'Sports'
            },
            {
              link: '#',
              image: require('@/assets/images/sports-image3.png'),
              imageAlt: 'Healthcare Image 3',
              title: 'Are you doing well your stretching?',
              authorImage: require('@/assets/images/articles-dr-enrique.png'),
              doctorImageAlt: 'Dr. Enrique Centeriuolo',
              authorName: 'By DR. Enrique Centeriuolo',
              category: 'Sports'
            }
          ]
        }
      ],
      topArticlesData: [
        {
          link: '#',
          image: require('@/assets/images/food-image3.png'),
          alt: '',
          title: 'Roasted Potato’s and how...',
          authorImage: require('@/assets/images/articles-dr-enrique.png'),
          authorName: 'DR. Enrique Centeriuolo',
          category: 'Top'
        },
        {
          link: '#',
          image: require('@/assets/images/food-image4.png'),
          alt: '',
          title: 'Roasted Potato’s and how...',
          authorImage: require('@/assets/images/articles-dr-enrique.png'),
          authorName: 'DR. Enrique Centeriuolo',
          category: 'Top'
        },
        {
          link: '#',
          image: require('@/assets/images/food-image5.png'),
          alt: '',
          title: 'Roasted Potato’s and how...',
          authorImage: require('@/assets/images/articles-dr-enrique.png'),
          authorName: 'DR. Enrique Centeriuolo',
          category: 'Top'
        }
      ],
      foodArticlesData: {
        category: 'Food',
        articles: [
          {
            link: '#',
            image: require('@/assets/images/food-image1.png'),
            alt: '',
            title: 'Roasted Potato’s and how...',
            authorImage: require('@/assets/images/articles-dr-enrique.png'),
            authorName: 'DR. Enrique Centeriuolo',
            category: 'Food'
          },
          {
            link: '#',
            image: require('@/assets/images/food-image2.png'),
            alt: '',
            title: 'Roasted Potato’s and how...',
            authorImage: require('@/assets/images/articles-dr-enrique.png'),
            authorName: 'DR. Enrique Centeriuolo',
            category: 'Food'
          },
          {
            link: '#',
            image: require('@/assets/images/food-image3.png'),
            alt: '',
            title: 'Roasted Potato’s and how...',
            authorImage: require('@/assets/images/articles-dr-enrique.png'),
            authorName: 'DR. Enrique Centeriuolo',
            category: 'Food'
          },
          {
            link: '#',
            image: require('@/assets/images/food-image4.png'),
            alt: '',
            title: 'Roasted Potato’s and how...',
            authorImage: require('@/assets/images/articles-dr-enrique.png'),
            authorName: 'DR. Enrique Centeriuolo',
            category: 'Food'
          },
          {
            link: '#',
            image: require('@/assets/images/food-image5.png'),
            alt: '',
            title: 'Roasted Potato’s and how...',
            authorImage: require('@/assets/images/articles-dr-enrique.png'),
            authorName: 'DR. Enrique Centeriuolo',
            category: 'Food'
          }
        ]
      },
    };
  },
  methods: {}
});
</script>

<style>
.knowledge_better_box {
  background: linear-gradient(
    269deg,
    rgba(0, 0, 0, 0) 12.85%,
    rgba(255, 255, 255, 0.2) 60.5%
  );
}
</style>
