import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/tailwind.css';
import './assets/main.css';
import { createI18n } from 'vue-i18n';
import { defaultLocale, messages } from './locales/localization';
import vue3GoogleLogin from 'vue3-google-login';
import clickOutside from './directives/click-outside';
import GlobalError from './components/globalError/GlobalError.vue';
import { CometChat } from '@cometchat-pro/chat';
import VueGoogleMaps from '@fawmi/vue-google-maps';


console.error = (message?: any) => {
  throw new Error(message);
};

const i18n = createI18n({
  legacy: false,
  locale: navigator.language.includes('es') ? 'es-ES' : (
    navigator.language.includes('en') ? 'en-US' : navigator.language),
  fallbackLocale: defaultLocale,
  messages,
  fallbackWarn: false,
  silentTranslationWarn: true,
});

//Mapping to translate navigator.language options to flatpickr languagesm get 'en' as default because defaultLocale is 'en-US'
const storeLocale = navigator.language.includes('es') ? 'es' : (
  navigator.language.includes('en') ? 'en' :
    navigator.language.includes('ca') ? 'cat' : 'en');
//TODO INFO: Para cuando tengamos que cambiar el idioma dependiendo del usuario hacemos overwrite del mapping de storeLocale
store.commit('setI18nLocale', storeLocale);



const appID = "2434008c7a89d230", //TODO buscar la manera de ocultar estos datos
  region = "EU",
  appSetting: CometChat.AppSettings = new CometChat.AppSettingsBuilder()
    .subscribePresenceForAllUsers()
    .setRegion(region)
    .build();
CometChat.init(appID, appSetting).then(
  (initialized: boolean) => {
    // Do nothing when initialized
  }, (error: CometChat.CometChatException) => {
    console.log("Initialization failed with error:", error);
  }
);


createApp(App)
.component('GlobalError', GlobalError)
.directive("click-outside", clickOutside)
.use(store)
.use(router)
.use(i18n)
.use(vue3GoogleLogin, {
	clientId: '577167699684-2ot1otvnfubaa88h9ll4nahj7ekk1jmb.apps.googleusercontent.com'
})
.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyCwC7CjdZnY8tkXHgHtl_t68k6HRt57ugU',
	},
})
.mount('#app');

