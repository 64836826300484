<template>

  <div class="fixed right-0 top-0 w-screen h-screen z-[9999] bg-black bg-opacity-50 backdrop-blur-3xl text-left transition-all duration-[600ms] pointer-events-none" v-if="isOpen">
    <div class="bg-white bg-opacity-50 backdrop-blur-md py-[2.4rem] px-[1.2rem]">
      <div class="">
        <div class="flex justify-between">
          <div class="w-1/2 text-left">
            <a href="index.html" class="logo">
							<img src="@/assets/images/offcanvas-logo.svg" alt="" />
            </a>
          </div>
          <div class="w-1/2 text-right">
            <button class="hamburger">
							<img src="@/assets/images/Hamburger_close.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="px-4 pt-12 pb-16 h-[calc(100vh-7rem)] flex flex-col mt-10">
      <ul class="offcanvasNav">
        <li><a href="#">Homepage</a></li>
        <li><a href="#">Articles</a></li>
        <li><a href="#">Professionals</a></li>
        <li><a href="#">about us</a></li>
      </ul>
      <div class="login_wrap">
        <a href="#">Login</a> / <a href="#">Register</a>
      </div>
    </div>

  </div>


</template>

<script>
  import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'MarketHeaderHamburger',
  props: {
    selectedHeader: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
});
</script>


<style scoped>

.offcanvasNav a {
	color: #FFF;
	font-size: 2.4rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.16;
	letter-spacing: 0.96px;
	text-transform: uppercase;
}

.offcanvasNav li:not(:last-child) {
	margin-bottom: 3.2rem;
}

</style>
