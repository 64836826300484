<template>
  <main>

      <InputField
        class="pb-[2.4rem]"
        v-model="specialty"
        :label="$t('Reason for the visit')"
        @input="validate"
        :required="true"
        ref="specialty"
        inputClass="input mt-4"
      />

      <InputField
        class="pb-[2.4rem]"
        v-model="insurance"
        :label="$t('Insurance provider')"
        @input="validate"
        :required="true"
        ref="insurance"
        inputClass="input mt-4"
      />

      <h4 class="text-black font-bold pb-4">{{$t('Is this your first visit with this specialist?')}}</h4>

      <div class="flex gap-[2.4rem]">
          <InputField
            inputType="checkbox"
            :label="$t('Yes')"
            v-model="firstVisitYes"
            @change="handleCheckboxChange('yes')"
          />
          <InputField
            inputType="checkbox"
            :label="$t('No')"
            v-model="firstVisitNo"
            @change="handleCheckboxChange('no')"
          />
      </div>

      <span v-if="showCheckboxError" class="error-message">{{$t('Please select either Yes or No')}}</span>

  </main>

  <CustomButton class="mt-10" :label="$t('Continue')" @click="goToNextStep" />

</template>

<script>
import { defineComponent } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import InputField from '@/components/input/inputField.vue';
import CustomButton from '@/components/buttons/customButton.vue';

export default defineComponent({
  name: 'CheckoutStepOneView',
  components: {
    InputField,
    CustomButton
  },
  data() {
    return {
      specialty: '',
      insurance: '',
      firstVisitYes: false,
      firstVisitNo: false,
      showCheckboxError: false
    };
  },
  methods: {
    validate() {
      const isCheckboxValid = (this.firstVisitYes || this.firstVisitNo) && !(this.firstVisitYes && this.firstVisitNo);
      this.showCheckboxError = !isCheckboxValid;

      this.$refs.specialty.updateValue({ target: { value: this.specialty } });
      this.$refs.insurance.updateValue({ target: { value: this.insurance } });

      const isValid = this.specialty && this.insurance && isCheckboxValid;
      this.$emit('validation', isValid);
    },
    handleCheckboxChange(option) {
      if (option === 'yes') {
        this.firstVisitNo = false;
        this.firstVisitYes = !this.firstVisitYes;
      } else {
        this.firstVisitYes = false;
        this.firstVisitNo = !this.firstVisitNo;
      }
      this.validate();
    },
    goToNextStep() {
      this.validate();
      if (!this.showCheckboxError && this.specialty && this.insurance) {
        const { date, hour, idDoctor, idAddress } = this.$route.params;
        this.$router.push({
          name: 'CheckoutStepTwo',
          params: {
            date,
            hour,
            idDoctor,
            idAddress,
          }
        });
      }
    }
  },
  watch: {
    firstVisitYes(newVal) {
      if (newVal) {
        this.firstVisitNo = false;
      }
    },
    firstVisitNo(newVal) {
      if (newVal) {
        this.firstVisitYes = false;
      }
    }
  }
});
</script>

<style scoped>

.input.is-invalid {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.75em;
}

.checkbox-label {
  position: relative;
  cursor: pointer;
  padding-left: 25px;
}

.checkbox-label.checked::before {
  background: url('@/assets/images/tic_mark.svg') no-repeat center;
}

.checkbox-label.is-invalid {
  color: red;
}

.checkbox-label.is-invalid::before {
  border: 1px solid #f00 !important;
}
</style>
