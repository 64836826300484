<template>

    <MarketHeader :selectedHeader="useHeader" />

    <main class="container mx-auto sm:px-4 pt-[3.9rem]" v-if="doctor">

            <div class="md:flex md:flex-wrap -mx-8">

              <div class="md:w-1/2 px-12">
              
                <MainContent paddingClass="py-6 px-9">

                  <AppointmentTitle />

                  <DoctorComponent class="block md:hidden sm:border-b-white sm:border-b sm:border-solid"
                    :doctor="doctor"
                    :showServiceTime="true" 
                    :showDoctorAddress="false"
                    :showPaymentMethod="false"
                    :showPersonalizedService="false"
										:showFirstConsultation="false"
                  />

                  <div class="mt-[2.4rem]">
                    <router-view @validation="handleValidation"></router-view>     
                  </div>
                </MainContent>
                
              </div>

              <div class="md:w-1/2 hidden md:block px-12">
                <MainContent paddingClass="py-6 px-9">
                  <DoctorComponent :doctor="doctor" :showServiceTime="true" />
                </MainContent>
              </div>
            </div>
    </main>

		<BackGround/>

</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import BackGround from '@/components/design/background.vue';

import MarketHeader from '@/components/header/header.vue';
import AppointmentTitle from '@/components/checkout/appointmentTitle.vue';
import DoctorComponent from '@/components/doctor/doctorComponent.vue';
import MainContent from '@/components/design/mainContent.vue';
import axios from 'axios';

export default defineComponent({
  name: "CheckoutLayout",
  components: {
    MarketHeader,
    AppointmentTitle,
    DoctorComponent,
    MainContent,
		BackGround
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const isFormValid = ref(false);

    onMounted(() => {
      const { date, hour, idDoctor, idAddress } = route.params;
    });

    const handleValidation = (isValid: boolean) => {
      isFormValid.value = isValid;
    };

    return {
      useHeader: "checkoutHeader",
      isFormValid,
      handleValidation
    };
  },
  data() {
    return {
      doctor: null
    };
  },
	mounted() {
		this.loadData();
	},
  methods: {
		async loadData() {
			try {
        const idDoctor = this.$route.params.idDoctor;
				var doctor = await axios.get('https://api.fernandosilva.es/api/medkus/doctors/' + idDoctor);
				this.doctor = doctor.data
			} catch (error) {
				console.error('Error fetching featured appointments:', error);
			}
		}
  }
});
</script>

<style scoped>

@import "../assets/css/helper.css";
@import "../assets/css/owl.carousel.min.css";
@import "../assets/css/responsive.css";
@import "../assets/css/style.css";

</style>
