<template>
    <img class="fixed w-screen h-screen object-cover opacity-60 z-[-1] left-0 top-0" src="@/assets/images/hero-bg.gif" alt="">
</template>

<script>
export default {
  name: 'BackToTop',
}
</script>


<style scoped>

</style>
