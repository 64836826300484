<template>

  <h3 class="text-black md:text-[1.6rem] sm:text-[1.6rem] font-medium">{{$t('services')}}</h3>

  <div class="mt-[2.4rem]">
    <TextList
      :list="services"
    />
  </div>

</template>

<script>

import TextList  from '@/components/form/textList.vue';

export default {
  name: 'servicesComponent',
  components: {
    TextList
  },
  props: {
    services: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
}
</script>

<style scoped>

</style>
