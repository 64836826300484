import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import store from '@/store';

import HomeView from '@/views/HomeView.vue';
import SearchView from '@/views/SearchView.vue';
import ProfileView from '@/views/ProfileView.vue';

import CheckoutStepOneView from '@/views/checkout/CheckoutStepOneView.vue';
import CheckoutStepTwoView from '@/views/checkout/CheckoutStepTwoView.vue';

//Login/Register
import LoginView from '@/views/loginRegister/LoginView.vue';
import RegisterView from '@/views/loginRegister/RegisterView.vue';

//Account
import AccountSettingsView from '@/views/account/AccountSettingsView.vue';
import FavoriteSpecialistsView from '@/views/account/FavoriteSpecialistsView.vue';
import PastAppointmentsView from '@/views/account/PastAppointmentsView.vue';
import UpcomingAppointmentsView from '@/views/account/UpcomingAppointmentsView.vue';

//Blog
import ArticlesView from '@/views/blog/ArticlesView.vue';
import CategorieView from '@/views/blog/CategorieView.vue';
import BlogPostView from '@/views/blog/BlogPostView.vue';

//Layout
import BaseLayout from '@/layouts/baseLayout.vue';
import SearchLayout from '@/layouts/searchLayout.vue';
import CheckoutLayout from '@/layouts/checkoutLayout.vue';
import AccountLayout from '@/layouts/accountLayout.vue';
import LoginRegisterLayout from '@/layouts/loginRegisterLayout.vue';
import BlogLayout from '@/layouts/blogLayout.vue';





const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: BaseLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: HomeView
      }
    ],
  },
  {
    path: '/search',
    component: SearchLayout,
    children: [
      {
        path: '/search',
        name: 'Search',
        component: SearchView
      },
      {
        path: '/profile/:id',
        name: 'Profile',
        component: ProfileView
      }
    ],
  },
  {
    path: '/checkout',
    component: CheckoutLayout,
    children: [
      {
        path: '1/:date/:hour/:idDoctor/:idAddress',
        name: 'CheckoutStepOne',
        component: CheckoutStepOneView
      },
      {
        path: '2/:date/:hour/:idDoctor/:idAddress',
        name: 'CheckoutStepTwo',
        component: CheckoutStepTwoView
      }
    ],
  },
  {
    path: '/loginRegister',
    component: LoginRegisterLayout,
    children: [
      {
        path: '/login',
        name: 'Login',
        component: LoginView
      },
      {
        path: '/register',
        name: 'Register',
        component: RegisterView
      }
    ],
  },
  {
    path: '/account',
    component: AccountLayout,
    children: [
      {
        path: '/account/settings',
        name: 'AccountSettings',
        component: AccountSettingsView
      },
      {
        path: '/account/favorite-specialists',
        name: 'FavoriteSpecialists',
        component: FavoriteSpecialistsView
      },
      {
        path: '/account/past-appointments',
        name: 'PastAppointments',
        component: PastAppointmentsView
      },
      {
        path: '/account/upcoming-appointments',
        name: 'UpcomingAppointments',
        component: UpcomingAppointmentsView
      }
    ],
  },
  {
    path: '/blogs',
    component: BlogLayout,
    children: [
      {
        path: '',
        name: 'ArticlesView',
        component: ArticlesView
      },
      {
        path: ':categorySlug',
        name: 'CategorieView',
        component: CategorieView
      },
      {
        path: ':categorySlug/:articleSlug',
        name: 'BlogPostView',
        component: BlogPostView
      }
    ],
  }
];


const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (store.state.globalErrorIsOpen === true) {
    //show global error
    next(false); // Cancel navigation 
  } else {
    next(); // Continue with navigation
  }
});

export default router;
