<template>
  <div 
    :class="!disableClass ? [
      'bg-white-50 rounded-[2.4rem] border border-white md:max-w-none max-w-full', 
      paddingClass || 'p-6'
    ] : ''"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SubContent',
  props: {
    paddingClass: {
      type: String,
      default: 'p-6'
    },
    disableClass: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
