<template>
  <div class="md:pt-48 sm:pt-[5.0rem] container mx-auto sm:px-4">
    <SubContent paddingClass="p-[2.4rem]">
    <div class="flex flex-wrap ">
      <div class="md:w-3/5">
        <div>
          <h3 class="text-black md:text-[2.6rem] sm:text-[1.8rem] font-semibold leading-[1.15] tracking-[-0.78px]">
            {{$t('Are you a healthcare specialist? Start')}}
            <br/>
            {{$t('Acquire new patients with Medkus.')}}
          </h3>
          <p class="max-w-[65.1rem] text-black leading-[1.6] px-0 py-[2.4rem]">
          {{$t('Connect with patients looking for specialists in your area. Allow your patients to book appointments day and night. Say goodbye to waiting for opening hours. Improve your online reputation with verified reviews.')}}
          </p>

          <CustomButton
            :label="$t('Request access today.')"
            />

        </div>
      </div>
      <div class="md:w-2/5 pr-4 pl-4">
        <div class="w-full h-full">
          <img class="w-full h-full" src="@/assets/images/white-flower-image.png" alt="health specialist" />
        </div>
      </div>
    </div>
    </SubContent>
  </div>
</template>

<script>

import CustomButton from '@/components/buttons/customButton.vue';
import { defineComponent } from "vue";
import SubContent from '@/components/design/subContent.vue';

export default defineComponent({
  name: 'HealthSpecialistSection',
  components: {
    CustomButton,
    SubContent
  },
});
</script>

<style scoped>


.health_specialis_box {
  background: linear-gradient(174deg, #fff 11.67%, #f7f9fa 82.14%);
}

</style>
