<template>
  <div class="flex items-center justify-between">
    <div class="blog_doctor_image flex items-center gap-[0.8rem]">
      <img class="w-[1.8rem] h-[1.8rem] rounded-[50%] w-[3.2rem] w-[3.2rem] h-[3.2rem]" :src="doctorImage" alt="" />
      <h3 :class="[additionalClass, 'text-left md:text-[10px] sm:text-[8.4px] sm:text-base']">{{$t('By')}} {{doctorName}}</h3>
    </div>

    <div v-if="date" class="text-[color:var(--black)] text-base">
      <span>{{date}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DoctorImage',
  props: {
    doctorImage: {
      type: String,
      required: true
    },
    doctorName: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: false
    },
    additionalClass: {
      type: String,
      required: false,
      default: 'text-white'
    }
  }
};
</script>

<style scoped>

.blog_doctor_image {
  justify-content: start;
}

</style>
