<template>
    <h4 v-if="title" class="text-black md:text-[1.2rem] sm:text-[1.2rem] font-medium mb-[0.8rem]">{{title}}</h4>
    <ul class="flex flex-col ml-8 gap-3 pl-8">
      <li class="text-black md:text-[1.2rem] sm:text-[1.2rem] font-medium list-disc" v-for="(item, index) in list" :key="index">{{ item }}</li>
    </ul>
    <a href="#" class="text-black md:text-[1.2rem] sm:text-[1.2rem] font-medium underline">Mostrar más</a>
</template>

<script>
export default {
  name: 'TextList',
  props: {
    list: {
      type: Array,
      required: true
    },
    title:{
        type: String,
        required: false
    }
  }
}
</script>

<style scoped>
</style>
