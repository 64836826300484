<template>

  <div class="fixed inset-0" id="mapModal" tabindex="-1">
<div class="modal-content w-full h-full fixed">

  <div class="offcanvas offcanvas-end pb-0 fixed w-[50rem] sm:w-full" tabindex="-1" id="offcanvasFilter">
    <div class="results_filters_card pb-0">
      <div class="results_filters_area">
        <div class="results_filters_card_top">
          <a class="flex justify-center items-center gap-[0.8rem] px-[3.2rem] py-[1.2rem]">
            <span class="text-black">{{$t('Filters')}}</span>
            <img class="w-[2.4rem]" src="@/assets/images/filters_icon.svg" alt="">
          </a>
          <a @click="closeFilters" class="location_delete_btn popup_remove_btn" data-bs-dismiss="offcanvas">x</a>
        </div>
        <div class="mt_32">
          <h4 class="text-black text-[1.6rem] text-center font-medium">{{$t('Online or in-person appointment?')}}</h4>
          <div class="flex items-center justify-center gap-4 text-center pt_16">
            <OnlineButton class="person_button"/>
            <InPersonButton class="person_button"/>
          </div>
        </div>
        <div class="mt_32">
          <h4 class="pb_16 text-black text-[1.6rem] text-center font-medium">{{$t('Insurance provider')}}</h4>
          <SelectInput :options="selectOptionsInsurance"/>
        </div>
        <div class="mt_32">
          <h4 class="pb_16 text-black text-[1.6rem] text-center font-medium">{{$t('Date')}}</h4>
          <SelectInput :options="selectOptions"/>
        </div>
        <div class="mt_32">
          <h4 class="text-black text-[1.6rem] text-center font-medium">{{$t('Payment method')}}</h4>
          <div class="flex items-center justify-center gap-4 text-center pt_16">
            <PayWithCardButton class="person_button"/>
            <PayWithCashButton class="person_button"/>
          </div>
        </div>
      </div>
      <div class="view_profile_button flex gap-4 flex-col mt-auto pt-[2.4rem]">
        <button class="view_button delete_apply_btn" @click="clearFilters">{{$t('Delete filters')}}</button>
        <button class="view_button delete_apply_btn btn_black" @click="applyFilters">{{$t('Apply filters')}}</button>
      </div>
    </div>
  </div>

</div>
</div>

</template>

<script>

import OnlineButton from '@/components/buttons/onlineButton.vue';
import InPersonButton from '@/components/buttons/inPersonButton.vue';
import PayWithCardButton from '@/components/buttons/payWithCardButton.vue';
import PayWithCashButton from '@/components/buttons/payWithCashButton.vue';
import SelectInput from '@/components/input/selectInput.vue';

export default {
  name: 'SearchFilter',
  components: {
    OnlineButton,
    InPersonButton,
    SelectInput,
    PayWithCardButton,
    PayWithCashButton
  },
  data() {
    return {
      selectOptionsInsurance: [
        { value: 'Ciudad', label: this.$t('City') },
        { value: 'Pais', label: this.$t('Country') }
      ],
      selectOptions: [
        { value: 'Ciudad', label: this.$t('City') },
        { value: 'Pais', label: this.$t('Country') }
      ],
    };
  },
  methods: {
    clearFilters() {
      // Logic to clear filters
    },
    applyFilters() {
      // Logic to apply selected filters
    },
    closeFilters(){
        this.$emit('close-filters');
    }
  }
};
</script>

<style scoped>
#mapModal{
  z-index: 1055;
}

.offcanvas.offcanvas-end{
    right: 0;
    top:0;
    bottom: 0;
}

.delete_apply_btn{
    width: 100% !important;
}

.select_box {
	@apply w-full inline-block shadow-[0px_4px_16px_0px_rgba(190,190,190,0.25)] backdrop-blur-md px-[1.6rem] py-0 rounded-[100px];
	background: rgba(255, 255, 255, 0.9) !important;
}

.select_box .select {
	background: none;
	outline: none;
}

.person_button {
	@apply flex-[1_0_0] block text-center;
}

#offcanvasFilter .person_button {
	@apply flex items-center justify-center px-0;
}


.results_filters_card .popup_remove_btn {
  @apply absolute -translate-y-2/4 border-[none] right-[1.6rem] top-2/4;
}

.results_filters_card {
  @apply w-full h-full flex flex-col p-[2.4rem];
}
.results_filters_card_top {
  @apply relative backdrop-blur-[2px] px-[1.6rem] py-[1.2rem] rounded-[10rem];
  background: #fff;
}

.results_filters_card .view_profile_button .view_button {
  @apply border-[none];
}
.results_filters_card .view_button {
  @apply h-[4.8rem];
}

.offcanvas.offcanvas-end {
  @apply border overflow-y-auto rounded-[2rem_0_0_2rem] border-solid border-white;
  background: rgba(245, 246, 246, 246);
}

</style>
