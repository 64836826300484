<template>

  <IntroSection />

  <MedicineAboutSection />

  <FeaturedDoctorsSection />

  <OurBlogSection />

  <OurClientsSection />

  <HealthSpecialistSection />

  <FooterMenuSection />
</template>

<script>
import { defineComponent, onMounted } from 'vue';

import MedicineAboutSection from '../components/home/medicineAboutSection.vue';
import OurBlogSection from '@/components/home/ourBlogSection.vue';
import FeaturedDoctorsSection from '@/components/home/featuredDoctorsSection.vue';
import IntroSection from '@/components/home/introSection.vue';

import OurClientsSection from '@/components/home/ourClientsSection.vue';
import HealthSpecialistSection from '@/components/home/healthSpecialistSection.vue';
import FooterMenuSection from '@/components/home/footerMenuSection.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    MedicineAboutSection,
    FooterMenuSection,
    HealthSpecialistSection,
    OurClientsSection,
    OurBlogSection,
    FeaturedDoctorsSection,
    IntroSection,
  },
  setup() {
    onMounted(() => {

      // Menú hamburguesa
//      $('.hamburger').click(() => {
 //       $('.offcanvasMenu').toggleClass('show');
//      });

/*

      // Botón de volver arriba
      $(window).on('scroll', () => {
        if ($(this).scrollTop() > 600) {
          $('.back-to-top').fadeIn(200);
        } else {
          $('.back-to-top').fadeOut(200);
        }
      });
*/

      document.querySelector('.back-to-top').addEventListener('click', function(event) {
          event.preventDefault();
          window.scrollTo({
              top: 0,
              behavior: 'smooth'
          });
      });

    });

  },
});
</script>
