<template>
  <div class="">

    <MarketHeader
      :selectedHeader="useHeader"
    />
    <main>
      <router-view></router-view>
    </main>

    <MarketFooter v-if="useFooter" />

		<BackGround/>

		<BackToTop/>

  </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import { useRoute } from 'vue-router';

import MarketHeader from '@/components/header/header.vue';
import MarketFooter from '@/components/footer/footer.vue';
import BackToTop from '@/components/design/backToTop.vue';
import BackGround from '@/components/design/background.vue';

export default defineComponent({
  name: "BaseLayout",
  components: {
    MarketHeader,
    MarketFooter,
		BackToTop,
		BackGround
  },
  setup() {

    const route = useRoute();

    return {
      useHeader: "defaultHeader",
      useFooter: true,
    };
  },
});

</script>

<style scoped>
	@import '../assets/css/helper.css';
	@import '../assets/css/owl.carousel.min.css';
	@import '../assets/css/responsive.css';
	@import '../assets/css/style.css';
</style>
