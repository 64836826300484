<template>
  <main>
    <div>
      <div class="md:flex">
        <div class="md:w-1/2">
            <InputField
              class="pb-[2.4rem]"
              v-model="name"
              :label="$t('Name')"
              :placeholder="$t('Name')"
              inputClass="input mt-4"
            />
        </div>
        <div class="md:w-1/2 md:pl-4">
            <InputField
              class="pb-[2.4rem]"
              v-model="lastname"
              :label="$t('Last Name')"
              :placeholder="$t('Last Name')"
              inputClass="input mt-4"
            />
        </div>
      </div>

			<InputField 
				class="pb-[2.4rem]"
				v-model="email" 
				:label="$t('Email *')"
				:placeholder="$t('Email *')"
				:required="true"
				ref="emailField"
				inputClass="input mt-4"
			/>

			<InputField
				class="pb-[2.4rem]"
				v-model="password"
				:label="$t('Password *')"
				:placeholder="$t('Password *')"
				inputType="password"
				:required="true"
				ref="passwordField"
				inputClass="input mt-4"
			/>

			<InputField
				v-model="consent"
				inputType="checkbox"
				required
				ref="consentField"
			>
				{{$t('I give my consent for Medkus to process my health data in order to use the services')}}
				<a href="#" class="underline">
					{{$t('Learn more')}}
				</a>

			</InputField>

			<InputField class="pt_16" inputType="checkbox">
				{{$t('I want to receive commercial communications from Medkus (optional).')}}
				<a href="#" class="underline">
					{{$t('Learn more')}}
				</a>
			</InputField>

      <CustomButton
        :label="$t('Continue')"
        @click="validateForm"
      />
    </div>
    <div class="pt-[2.4rem] pb-20">
      <p class="text-black text-[1.4rem] leading-[1.7] font-jost">
        {{$t('When you register, you confirm that you agree to our')}}
        <a href="#" class="underline">
          {{$t('terms and conditions')}}
        </a>
        y que entiendes nuestra
        <a href="#" class="underline">
          {{$t('privacy policy.')}}
        </a>
      </p>
    </div>
    <div class="pt-[2.4rem]">
      <p class="text-black text-[1.4rem] md:text-[1.8rem]">
        {{$t('Do you already have an account on Medkus?')}}
        <router-link to="/login" class="underline">
          {{$t('Enter here')}}
        </router-link>
      </p>
    </div>
  </main>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter } from 'vue-router';
import InputField from '@/components/input/inputField.vue';
import CustomButton from '@/components/buttons/customButton.vue';

export default defineComponent({
  name: "RegisterView",
  components: {
    CustomButton,
    InputField
  },
  data() {
    return {
      name: '',
      lastname: '',
      email: '',
      password: '',
      consent: false
    };
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  methods: {
    validateForm() {
      this.$refs.emailField.updateValue({ target: { value: this.email } });
      this.$refs.passwordField.updateValue({ target: { value: this.password } });
      this.$refs.consentField.updateValue({ target: { checked: this.consent } });

      const isEmailInvalid = this.$refs.emailField.isInvalid;
      const isPasswordInvalid = this.$refs.passwordField.isInvalid;
      const isConsentInvalid = this.$refs.consentField.isInvalid;

      if (!isEmailInvalid || !isPasswordInvalid || !isConsentInvalid) {
        this.router.push('/account/settings');
      }
    }
  }
});
</script>


<style scoped>


</style>
