<!-- BaseModal.vue -->
<template>
  <div class="modal-backdrop fixed inset-0 flex items-center justify-center z-50">
    <div class="modal-overlay absolute inset-0 opacity-50"></div>
    <div class="custom-modal-container modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
      <div class="p-6 text-left">
        <div class="flex justify-between items-center pb-3 appointment_card_top">
          <h3 class="text-2xl font-bold text-black text-[2.4rem] font-medium">{{ title }}</h3>
          <a href="#" class="location_delete_btn popup_remove_btn" @click.prevent="emitCloseModal">x</a>
        </div>
        <div class="appointment_area mt-4">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    emitCloseModal() {
      this.$emit('close-modal');
    },
  },
};
</script>

<style scoped>
  .custom-modal-container {
      max-width: 65.8rem;
      border-radius: 2rem;
  }
</style>
