<template>

  <MainContent :disableClass="isMobile ? true : false" paddingClass="md:w-[28.8rem] md:sticky md:min-h-[calc(100vh_-_8rem)] md:flex md:flex-col md:px-0 md:py-[3.2rem] md:rounded-none top-32">
    <ul class="sm:flex-row sm:flex-nowrap flex md:flex-wrap list-none mb-0 border border-t-0 border-r-0 border-l-0 border-b-1 border-gray-200 border-0 md:flex-col md:gap-[3.6rem] sm:gap-0 md:pl-24 sm:p-0 sm:overflow-auto sm:scrollbar-width: none sm:whitespace-nowrap no-scrollbar">
      <li v-for="tab in tabs" :key="tab.id">
        <router-link
          :to="tab.route"
          :class="['appointments_button', { active: isActive(tab.route) }]"
        >
          {{ tab.label }}
        </router-link>
      </li>
    </ul>
    <div class="text-center mt-auto sm:hidden block">
      <a href="#" class="text-black text-[1.2rem] capitalize px-0 py-4 hover:text-inherit hover:font-bold">{{ $t('Sign Off') }}</a>
    </div>
  </MainContent>

</template>

<script>

import MainContent from '@/components/design/mainContent.vue';

export default {
  props: {
    defaultActiveTab: {
      type: String,
      default: '/account/upcoming-appointments'
    }
  },
  components: {
    MainContent
  },
  data() {
    return {
      tabs: [
        { id: 'tab1', route: '/account/upcoming-appointments', label: this.$t('Upcoming Appointments') },
        { id: 'tab2', route: '/account/past-appointments', label: this.$t('Past Appointments') },
        { id: 'tab3', route: '/account/favorite-specialists', label: this.$t('Favorite Specialists') },
        { id: 'tab4', route: '/account/settings', label: this.$t('Account Settings') },
      ],
			isMobile: false,
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
		checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
	created() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
};
</script>

<style scoped>

.appointments_sidebar_area {
	background: rgba(255, 255, 255, 0.5);
}

.appointments_button {
	@apply text-black text-[1.2rem] capitalize inline-block hover:text-black;
}

.appointments_button.active,
.appointments_button:hover {
	@apply font-bold;
}

.no-scrollbar {
	scrollbar-width: none; /* Firefox */
}
.no-scrollbar::-webkit-scrollbar {
	display: none; /* Chrome, Safari y Edge */
}

</style>
