<template>
  <div class="md:mt-[-9rem] container mx-auto sm:px-4 sm:mt-[5rem]">
    <div class="md:py-[20rem]">
      <h2 class="md:text-[4rem] sm:text-[1.8rem] text-black font-semibold leading-[1.4]">
        "{{$t('Integrative medicine treats the person as a whole')}}
        <br />
        {{$t('Wholeness: mind, body, and spirit')}}" <br />
        {{$t('- Andrew Weil')}}
      </h2>
      <p class="max-w-[62.8rem] text-[#627282] md:text-[1.8rem] sm:text-[1.2rem] font-medium leading-[1.33] px-0 py-[2.4rem]">
      {{$t('Find the doctor who best fits your needs. Search among over 1300 specialties in integrative medicine')}}
      </p>

      <div class="md:flex gap-[1.4rem] sm:row">
        <OnlineButton class="sm:w-6/12"/>

        <InPersonButton class="sm:w-6/12"/>

        <InputField class="md:w-3/12 sm:mt-4"
          v-model="specialty"
          :placeholder="$t('Specialty, illness, or name...')"
        />

        <SelectInput class="sm:w-full md:w-2/12 sm:mt-4"
         :options="selectOptions"
         />

        <CustomButton class="sm:w-full sm:mt-4"
          :label="$t('Search')"
        />
      </div>

    </div>
  </div>

</template>

<script>

import { defineComponent } from "vue";

import InputField from '@/components/input/inputField.vue';
import SelectInput from '@/components/input/selectInput.vue';
import CustomButton from '@/components/buttons/customButton.vue';

import OnlineButton from '@/components/buttons/onlineButton.vue';
import InPersonButton from '@/components/buttons/inPersonButton.vue';


export default defineComponent({
  name: 'IntroSection',
  components: {
    InputField,
    SelectInput,
    CustomButton,
    InPersonButton,
    OnlineButton
  },
  data() {
    return {
      selectOptions: [
        { value: 'Ciudad', label: this.$t('City') },
        { value: 'Pais', label: this.$t('Country') }
      ]
    };
  }
});
</script>

<style scoped>

</style>
