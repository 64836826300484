<template>
  <BaseButton class="bg-white inline-flex justify-center items-center" @click="toggleCheck">
    <img class="w-[1.6rem]" :src="imgSrc" :alt="imgAlt" />
    <span class="px-2 py-0 text-black">{{ text }}</span>
    <img class="w-[1.6rem]"
      :src="isChecked ? require('@/assets/images/tic_mark.svg') : require('@/assets/images/Circle_Check.svg')" 
      alt="Check Image" 
    />
  </BaseButton>
</template>

<script>

import BaseButton from '@/components/buttons/baseButton.vue';

export default {
  name: 'ImageButtonCheck',
	components: {
		BaseButton
	},
  props: {
    imgSrc: {
      type: String,
      required: true
    },
    imgAlt: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isChecked: false
    };
  },
  methods: {
    toggleCheck() {
      this.isChecked = !this.isChecked;
    }
  }
}
</script>

<style scoped>

</style>
