<template>
  <div class="">

    <MarketHeader
      :selectedHeader="useHeader"
      />

    <main class="sm:px-4 flex items-center justify-center pt-[3.9rem] pb-32">
      <MainContent class="w-[47.7rem] p-[2.4rem]">

      <h3 class="font-medium text-black text-[2.4rem] pb-[2.4rem] sm:text-center md:text-left">{{$t('Welcome')}}</h3>

      <SocialButton />

      <div class="flex justify-center items-center gap-2.5">
        <span class="bg-white w-[19.65rem] h-px"></span>
        <span>{{$t('Or')}}</span>
        <span class="bg-white w-[19.65rem] h-px"></span>
      </div>

      <router-view></router-view>
      </MainContent>
    </main>

    <BackGround/>

  </div>
</template>

<script>

  import { defineComponent } from "vue";
import { useRoute } from 'vue-router';

import BackGround from '@/components/design/background.vue';
import MarketHeader from '@/components/header/header.vue';
import SocialButton from '@/components/buttons/socialButton.vue';
import MainContent from '@/components/design/mainContent.vue';


export default defineComponent({
  name: "BaseLayout",
  components: {
    MarketHeader,
    SocialButton,
    MainContent,
    BackGround
  },
  setup() {

    const route = useRoute();

    return {
      useHeader: "loginRegisterHeader"
    };
  },
});

</script>

<style scoped>
@import '../assets/css/helper.css';
@import '../assets/css/owl.carousel.min.css';
@import '../assets/css/responsive.css';
@import '../assets/css/style.css';
</style>
