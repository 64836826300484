<template>
  <div>
    <div>

      <ul class="flex border rounded-[10rem] border-solid border-[rgba(0,0,0,0.06)] address_list overflow-x-auto whitespace-nowrap">
        <li class="sm:min-w-[100px] w-3/12 border-r-[rgba(0,0,0,0.06)] border-r border-solid shrink-0" v-for="(address, index) in addresses" :key="index">
          <a href="#" :class="{ active: activeAddressIndex === index }" class="block text-black font-medium text-center px-0 py-[0.8rem] flex items-center justify-center" @click.prevent="setActiveAddress(index)">
            <img v-if="address.icon" :class="address.iconClass + ' mr-2'" :src="address.icon" :alt="address.name" />
            {{ address.name }}
          </a>
        </li>
      </ul>

      <div class="flex items-center justify-start gap-1 px-0 py-[2.4rem] sm:py-[1.2rem]" :class="{ 'sm:hidden': isPopup }">
        <img class="w-[1.6rem]" src="@/assets/images/map_pin.svg" alt="Map pin" />
        <p class="text-black text-[1.2rem] font-medium">{{ addresses[activeAddressIndex].location }}</p>
      </div>
    </div>
    <div class="select_doctor_schedule">
      <h2 class="text-black text-[1.4rem] font-bold text-center" :class="{ 'sm:hidden': isPopup }" >{{$t('Select schedule')}}</h2>

      <div class="container mx-auto sm:px-4">
        <div class="w-full relative p-[2.4rem] sm:p-[1.2rem]">
          <button type="button" aria-label="Previous" class="date-slider-prev" :disabled="currentDateGroupIndex === 0" @click="prevDateGroup">
            <svg class="turn-back-icon" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
              <path d="M6.89381 1.99718C6.94268 1.94547 6.98089 1.88463 7.00624 1.81815C7.0316 1.75168 7.04362 1.68085 7.04161 1.60973C7.0396 1.53861 7.0236 1.46858 6.99452 1.40364C6.96544 1.33871 6.92386 1.28013 6.87215 1.23126C6.82043 1.18239 6.7596 1.14419 6.69312 1.11883C6.62665 1.09347 6.55582 1.08146 6.4847 1.08347C6.41358 1.08548 6.34355 1.10148 6.27861 1.13056C6.21367 1.15963 6.1551 1.20122 6.10623 1.25293L1.50206 6.12793C1.40698 6.2285 1.354 6.36165 1.354 6.50005C1.354 6.63846 1.40698 6.77161 1.50206 6.87218L6.10623 11.7477C6.15478 11.8006 6.21334 11.8432 6.27852 11.8733C6.34369 11.9033 6.41419 11.9201 6.4859 11.9226C6.55761 11.9252 6.62912 11.9134 6.69626 11.8881C6.7634 11.8628 6.82485 11.8244 6.87702 11.7751C6.92919 11.7258 6.97106 11.6667 7.00018 11.6011C7.02931 11.5355 7.04511 11.4648 7.04667 11.3931C7.04823 11.3213 7.03552 11.25 7.00928 11.1832C6.98304 11.1164 6.94379 11.0555 6.89381 11.004L2.64065 6.50005L6.89381 1.99718Z" fill="black"/>
            </svg>
            Previous
          </button>


          <div class="overflow-hidden w-full flex justify-center items-center">
            <div class="days-container w-full flex justify-evenly">
              <button v-for="(date, index) in currentDates" :key="index" 
                      class="date-slider-day flex-1 text-center py-2"
                      :class="{ 'activeDate': activeDateIndex === index }"
                      @click="setActiveDate(index)">
                <span>{{ date.day }}</span>
                <p>{{ date.weekday }}</p>
              </button>
            </div>
          </div>

          <button type="button" aria-label="Next" class="date-slider-next" :disabled="currentDateGroupIndex === totalDateGroups - 1" @click="nextDateGroup">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
              <path d="M6.10619 11.0028C6.05732 11.0545 6.01911 11.1154 5.99376 11.1818C5.9684 11.2483 5.95638 11.3191 5.95839 11.3903C5.9604 11.4614 5.9764 11.5314 6.00548 11.5964C6.03456 11.6613 6.07614 11.7199 6.12785 11.7687C6.17957 11.8176 6.2404 11.8558 6.30688 11.8812C6.37335 11.9065 6.44418 11.9185 6.5153 11.9165C6.58642 11.9145 6.65645 11.8985 6.72139 11.8694C6.78633 11.8404 6.8449 11.7988 6.89377 11.7471L11.4979 6.87207C11.593 6.7715 11.646 6.63835 11.646 6.49995C11.646 6.36154 11.593 6.22839 11.4979 6.12782L6.89377 1.25228C6.84522 1.19943 6.78666 1.15676 6.72148 1.12674C6.65631 1.09672 6.58581 1.07995 6.5141 1.0774C6.44239 1.07485 6.37088 1.08658 6.30374 1.1119C6.2366 1.13722 6.17515 1.17563 6.12298 1.22489C6.07081 1.27416 6.02894 1.3333 5.99982 1.39889C5.97069 1.46447 5.95489 1.53519 5.95333 1.60693C5.95177 1.67867 5.96448 1.75001 5.99072 1.8168C6.01696 1.88359 6.05621 1.9445 6.10619 1.99599L10.3594 6.49995L6.10619 11.0028Z" fill="black"/>
            </svg>
            Next
          </button>
        </div>
      </div>
    </div>
    <div class="border-t-[#EEE] border-t border-solid sm:hidden">
      <div class="schedule_time" v-for="index in 4" :key="index">
        <button @click="selectTime(currentTimesA(index-1, true), currentTimesA(index-1))">{{ currentTimesA(index-1) }}</button>
        <button @click="selectTime(currentTimesB(index-1, true), currentTimesB(index-1))">{{ currentTimesB(index-1) }}</button>
        <button @click="selectTime(currentTimesC(index-1, true), currentTimesC(index-1))">{{ currentTimesC(index-1) }}</button>
        <button @click="selectTime(currentTimesD(index-1, true), currentTimesD(index-1))">{{ currentTimesD(index-1) }}</button>
      </div>
    </div>
    <div class="border-t-[#EEE] border-t border-solid hidden sm:block">
      <div class="schedule_time" v-for="index in 1" :key="index">
        <button @click="selectTime(currentTimesA(index-1, true), currentTimesA(index-1))">{{ currentTimesA(index-1) }}</button>
        <button @click="selectTime(currentTimesB(index-1, true), currentTimesB(index-1))">{{ currentTimesB(index-1) }}</button>
        <button @click="selectTime(currentTimesC(index-1, true), currentTimesC(index-1))">{{ currentTimesC(index-1) }}</button>
        <button @click="selectTime(currentTimesD(index-1, true), currentTimesD(index-1))">{{ currentTimesD(index-1) }}</button>
      </div>
    </div>
    <div class="pt-[2.2rem] text-center">
      <a href="#" class="text-black font-medium text-center underline transition-[0.3s] duration-[ease] hover:text-inherit hover:opacity-80">Cargar más horarios</a>
    </div>
  </div>
</template>

<script>

import { useRouter } from 'vue-router';

export default {
  name: 'DoctorSchedule',

  props: {
    isPopup: {
      type: Boolean,
      default: false
    },
    doctor: null
  },
  data() {
    return {
      addresses: [
        { 
          id: 1,
          name: 'Direccion 1', 
          location: 'Carrer de Santa Dorotea 90, Barcelona, España.', 
          icon: null, 
          iconClass: '', 
          schedule: [
            { date: '2024-06-25', times: ['13:00', '19:00', '13:00', '13:30'] },
            { date: '2024-06-26', times: ['-', '-', '14:00', '-'] },
            { date: '2024-06-27', times: ['15:00', '15:00', '-', '15:00'] },
            { date: '2024-06-28', times: ['16:00', '16:00', '-', '16:00'] },
            { date: '2024-06-29', times: ['17:00', '18:00', '-', '19:00'] },
            { date: '2024-06-30', times: ['17:00', '17:00', '-', '17:00'] },
          ]
        },
        { 
          id: 2,
          name: 'Direccion 2', 
          location: 'Carrer de Balmes 12, Barcelona, España.', 
          icon: null, 
          iconClass: '', 
          schedule: [
            { date: '2024-06-28', times: ['12:00', '18:00', '12:00', '12:00'] },
            { date: '2024-06-29', times: ['-', '-', '13:00', '-'] },
            { date: '2024-06-30', times: ['14:00', '14:00', '-', '14:00'] },
            { date: '2024-07-01', times: ['15:00', '15:00', '-', '15:00'] }
          ]
        },
        { 
          id: 3,
          name: 'Direccion 3', 
          location: 'Avinguda Diagonal 456, Barcelona, España.', 
          icon: null, 
          iconClass: '', 
          schedule: [
            { date: '2024-07-01', times: ['11:00', '17:00', '11:00', '11:00'] },
            { date: '2024-07-02', times: ['-', '-', '12:00', '-'] },
            { date: '2024-07-03', times: ['13:00', '13:00', '-', '13:00'] },
            { date: '2024-07-04', times: ['14:00', '14:00', '-', '14:00'] }
          ]
        },
        { 
          id: 4,
          name: 'Online', 
          location: 'Consultas en línea', 
          icon: require('@/assets/images/online.svg'), 
          iconClass: 'pr_10', 
          schedule: [
            { date: '2024-07-04', times: ['10:00', '16:00', '10:00', '10:00'] },
            { date: '2024-07-05', times: ['-', '-', '11:00', '-'] },
            { date: '2024-07-06', times: ['12:00', '12:00', '-', '12:00'] },
            { date: '2024-07-07', times: ['13:00', '13:00', '-', '13:00'] }
          ]
        },
      ],
      activeAddressIndex: 0,
      currentDateGroupIndex: 0,
      activeDateIndex: 0,
      doctorId: 5,
    };
  },
  computed: {
    totalDateGroups() {
      return Math.ceil(this.addresses[this.activeAddressIndex].schedule.length / 4);
    },
    currentDates() {
      const start = this.currentDateGroupIndex * 4;
      const end = start + 4;
      return this.addresses[this.activeAddressIndex].schedule.slice(start, end).map(schedule => this.formatDate(schedule.date));
    },
  },
  methods: {
    setActiveAddress(index) {
      this.activeAddressIndex = index;
      this.currentDateGroupIndex = 0;
      this.activeDateIndex = 0;
    },
    currentTimesA(index, getDate=false) {
      const schedule = this.addresses[this.activeAddressIndex].schedule[this.currentDateGroupIndex * 4 + 0];
      if(getDate){
        return schedule ? schedule.date : "-";
      }
      return schedule ? schedule.times[index] : "-";
    },
    currentTimesB(index, getDate=false) {
      const schedule = this.addresses[this.activeAddressIndex].schedule[this.currentDateGroupIndex * 4 + 1];
      if(getDate){
        return schedule ? schedule.date : "-";
      }
      return schedule ? schedule.times[index] : "-";
    },
    currentTimesC(index, getDate=false) {
      const schedule = this.addresses[this.activeAddressIndex].schedule[this.currentDateGroupIndex * 4 + 2];
      if(getDate){
        return schedule ? schedule.date : "-";
      }
      return schedule ? schedule.times[index] : "-";
    },
    currentTimesD(index, getDate=false) {
      const schedule = this.addresses[this.activeAddressIndex].schedule[this.currentDateGroupIndex * 4 + 3];
      if(getDate){
        return schedule ? schedule.date : "-";
      }
      return schedule ? schedule.times[index] : "-";
    },
    prevDateGroup() {
      if (this.currentDateGroupIndex > 0) {
        this.currentDateGroupIndex--;
        this.activeDateIndex = 0;
      }
    },
    nextDateGroup() {
      if (this.currentDateGroupIndex < this.totalDateGroups - 1) {
        this.currentDateGroupIndex++;
        this.activeDateIndex = 0;
      }
    },
    setActiveDate(index) {
      this.activeDateIndex = index;
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const options = { weekday: 'short', day: '2-digit' };
      const formattedDate = date.toLocaleDateString('en-US', options);
      const [weekday, day] = formattedDate.split(' ');
      return { weekday: weekday.toUpperCase(), day };
    },
    selectTime(date, time) {
      const idAddress = this.addresses[this.activeAddressIndex].id;
      this.$router.push({
        name: 'CheckoutStepOne',
        params: {
          date,
          hour: time,
          idDoctor: this.doctor.id,
          idAddress
        }
      });
    }
  }
}
</script>

<style scoped>

.days-container {
  @apply items-center flex transition-transform w-full select-none mx-[1%] my-auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.date-slider-prev {
  @apply left-2;
}
.date-slider-next {
  @apply right-2;
}
.date-slider-prev:before,
.date-slider-next:before {
  @apply text-[5rem] leading-loose text-[#0079d2];
}
.date-slider-prev,
.date-slider-next {
  @apply text-[0] leading-[0] absolute block translate-x-0 -translate-y-2/4 cursor-pointer text-transparent p-0 border-[none] top-2/4;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  outline: none;
  background: transparent;
}
.date-slider-prev[disabled] {
  @apply cursor-auto;
}

.date-slider-day {
  @apply flex justify-center items-center text-center flex-col gap-4 text-[#8B8B8B] text-[1.2rem] bg-[white] shrink-0 relative text-center transition-transform mr-4 border-[none];
  -webkit-flex-shrink: 0;
}
.date-slider-day[date-slider-selected="true"] {
  @apply text-black text-[1.4rem];
}

.schedule_time {
  @apply grid grid-cols-[repeat(4,1fr)] items-center justify-center gap-4 pt-[1.6rem] px-12 py-0;
}

.schedule_time button {
  @apply text-black font-medium w-full text-[1.4rem] px-0 py-4 rounded-[7rem] border-[none];
  background: #f8f8f8;
}
.schedule_time button:not(.bg-transparent):hover {
  @apply text-white shadow-[0px_4px_4px_0px_rgba(0,0,0,0.70)_inset];
  background: #464646;
}

.active, .address_list li a:hover {
  @apply text-white bg-[#464646] shadow-[inset_0_0.4rem_0.4rem_rgba(0,0,0,0.70)];
}

.address_list li:first-child a {
  border-radius: 10rem 0 0 10rem;
}

.address_list li:last-child a {
  border-radius: 0 10rem 10rem 0;
}

.address_list li a:hover img, .active img {
  filter: brightness(0) saturate(100%) invert(96%) sepia(99%) saturate(1%) hue-rotate(141deg) brightness(105%) contrast(100%);
}

.activeDate{
  color: black;
}


</style>
