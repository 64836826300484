<template>
  <BaseModal
    :title="''"
    @close-modal="closeModal"
  >
    <div v-if="doctor">
      <h3 class="text-black text-center text-[2.4rem] font-medium leading-[1.33]">
        Como ha ido tu cita con el {{doctor.name}}
      </h3>
      <DoctorInformation
        :doctor="doctor"
        class="pt_24 pb_24 justify-content-lg-center"
      />
      <div class="review_rating_box">
        <img
          class="w-96 block mx-auto my-0 pb_24"
          src="@/assets/images/popup-review-image.png"
          alt="Review"
        />

        <TextareaField
          v-model="review"
          :placeholder="$t('Write about how your experience was...')"
          textareaClass="textarea bg-[rgba(239,239,239,0.70)] pb-0"
        />
      </div>
    </div>

      <CustomButton class="mt-[3.2rem] review_button border block mx-auto my-0 px-48 py-[1.6rem] rounded-[10rem] border-solid border-black btn_black"
        :label="$t('Return')"
        @click="closeModal"
      />

  </BaseModal>
</template>

<script>
import BaseModal from '@/components/popup/modal/baseModal.vue';

import DoctorInformation from '@/components/doctor/doctorInformation.vue';
import TextareaField from '@/components/input/textareaField.vue';
import CustomButton from '@/components/buttons/customButton.vue';

export default {
  name: 'ReviewPopup',
  components: {
    DoctorInformation,
    TextareaField,
    BaseModal,
    CustomButton
  },
  props: {
    doctor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      review: ''
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

@media (min-width: 992px) {
    .justify-content-lg-center {
        justify-content: center !important;
    }
}

.review_button {
	background: #000;
}

</style>
