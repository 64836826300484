<template>
  <div 
    :class="[
      'bg-white backdrop-blur-[2px] rounded-[2.4rem]', 
      paddingClass || 'px-[3.2rem] sm:px-[1.6rem] py-[2.4rem]'
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SubContent',
  props: {
    paddingClass: {
      type: String,
      default: 'px-[3.2rem] sm:px-[1.6rem] py-[2.4rem]'
    }
  }
}
</script>


<style scoped>

</style>
