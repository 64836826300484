<template text-black>
  <div class="sticky z-[1024] backdrop-blur-[22px] left-0 top-[7.8rem] lg:block sm:hidden">
    <div class="container mx-auto sm:px-4">
      <div class="flex items-center justify-center gap-[1.6rem] px-0 py-[1.6rem] gap-[0.4rem]">

        <OnlineButton/>

        <InPersonButton/>

        <CalendarButton/>

        <FilterButton @click.prevent="handleViewFilters"/>

      </div>
    </div>
  </div>

  <SearchFilter
    v-if="isFilterVisible"
    @close-filters="closeFilters"
    />

</template>

<script>

import OnlineButton from '@/components/buttons/onlineButton.vue';
import InPersonButton from '@/components/buttons/inPersonButton.vue';
import CalendarButton from '@/components/buttons/calendarButton.vue';
import FilterButton from '@/components/buttons/filterButton.vue';

import SearchFilter from '@/components/search/filters.vue';

import EventBus from '@/helpers/eventBus';

export default {
  name: 'SearchResultSection',
  components: {
    InPersonButton,
    OnlineButton,
    SearchFilter,
    CalendarButton,
    FilterButton,
  },
  data() {
    return {
      isFilterVisible: false
    };
  },
  created() {
    EventBus.addEventListener('toggle-filter', this.toggleFilter);
  },
  methods: {
    handleViewFilters() {
      this.isFilterVisible = true
    },
    closeFilters() {
      this.isFilterVisible = false
    },
    toggleFilter() {
      this.isFilterVisible = !this.isFilterVisible;
    }
  },
  beforeUnmount() {
    EventBus.removeEventListener('toggle-filter', this.toggleFilter); // Limpia el listener al desmontar
  }
}
</script>

<style scoped>

</style>
