<template>
  <BaseAllArticles class="md:pt-48 sm:pt-[5.0rem]"
    :link="`/blogs/`"
    :title="$t('Our Blog')"
  >

	<div>
		<SliderComponent
			:arrayData=blogs
			type="one"
		/>
	</div>

  </BaseAllArticles>

</template>

<script>

import { defineComponent, onMounted } from 'vue';
import BaseAllArticles from '@/components/blog/articles/baseAllArticles.vue';
import SliderComponent from '@/components/blog/slider/sliderComponent.vue';

export default defineComponent({
  name: 'OurBlogSection',
  components: {
    BaseAllArticles,
		SliderComponent
  },
  data() {
    return {
      blogs: [
        {
          image: require('@/assets/images/blog-image1.png'),
          doctorImage: require('@/assets/images/blog-dr-image1.png'),
          doctorName: 'DR. Enrique Centeriuolo',
          title: '10 way to reduce back pain',
          description:
            'Explora la tuberculosis en profundidad. Identifica síntomas, explora causas y descubre opciones de tratamiento para comprender y gestionar esta enfermedad...',
          buttonText: 'Ver todo los artículos de Neumologia.'
        },
        {
          image: require('@/assets/images/blog-image_3.png'),
          doctorImage: require('@/assets/images/blog-dr-image1.png'),
          doctorName: 'DR. Enrique Centeriuolo',
          title: '10 way to reduce back pain',
          description:
            'Explora la tuberculosis en profundidad. Identifica síntomas, explora causas y descubre opciones de tratamiento para comprender y gestionar esta enfermedad...',
          buttonText: 'Ver todo los artículos de Neumologia.'
        },
        {
          image: require('@/assets/images/blog-image_1.png'),
          doctorImage: require('@/assets/images/blog-dr-image1.png'),
          doctorName: 'DR. Enrique Centeriuolo',
          title: '10 way to reduce back pain',
          description:
            'Explora la tuberculosis en profundidad. Identifica síntomas, explora causas y descubre opciones de tratamiento para comprender y gestionar esta enfermedad...',
          buttonText: 'Ver todo los artículos de Neumologia.'
        },
        {
          image: require('@/assets/images/blog-image_3.png'),
          doctorImage: require('@/assets/images/blog-dr-image1.png'),
          doctorName: 'DR. Enrique Centeriuolo',
          title: '10 way to reduce back pain',
          description:
            'Explora la tuberculosis en profundidad. Identifica síntomas, explora causas y descubre opciones de tratamiento para comprender y gestionar esta enfermedad...',
          buttonText: 'Ver todo los artículos de Neumologia.'
        },
        {
          image: require('@/assets/images/blog-image_1.png'),
          doctorImage: require('@/assets/images/blog-dr-image1.png'),
          doctorName: 'DR. Enrique Centeriuolo',
          title: '10 way to reduce back pain',
          description:
            'Explora la tuberculosis en profundidad. Identifica síntomas, explora causas y descubre opciones de tratamiento para comprender y gestionar esta enfermedad...',
          buttonText: 'Ver todo los artículos de Neumologia.'
        }
      ]
    }
  },
});
</script>

<style scoped>


</style>
