<template>
  <div class="md:pt-48 sm:pt-[5.0rem] md:pb-40 sm:pb-[2.5rem] container mx-auto sm:px-4">
		<FooterMenu :footerMenus="footerMenus" />
  </div>
</template>

<script>

import FooterMenu from '@/components/home/footerMenu.vue';

export default {
  name: 'FooterMenuSection',
  components: {
    FooterMenu
  },
  data() {
    return {
      footerMenus: [
        [
          { text: 'Psicólogo', link: '#' },
          { text: 'Ginecólogo', link: '#' },
          { text: 'Traumatólogo', link: '#' },
          { text: 'Dermatólogo', link: '#' },
          { text: 'Psiquiatra', link: '#' },
          { text: 'Dentista', link: '#' },
          { text: 'Médico general', link: '#' },
          { text: 'Otorrino', link: '#' },
          { text: 'Oftalmólogo', link: '#' },
          { text: 'Urólogo', link: '#' },
          { text: 'Podólogo', link: '#' },
          { text: 'Alergólogo', link: '#' },
        ],
        [
          { text: 'Terapia de pareja', link: '#' },
          { text: 'Psicoterapia', link: '#' },
          { text: 'Implante dental', link: '#' },
          { text: 'Tratamientos con neurotoxina', link: '#' },
          { text: 'Nutrición deportiva', link: '#' },
          { text: 'Ecografía 4d', link: '#' },
          { text: 'Hipopresivos', link: '#' },
          { text: 'Osteopatía', link: '#' },
        ],
        [
          { text: 'Adeslas', link: '#' },
          { text: 'Asisa', link: '#' },
          { text: 'Sanitas', link: '#' },
          { text: 'DKV Seguros', link: '#' },
          { text: 'Mapfre Caja Salud', link: '#' },
          { text: 'Caser', link: '#' },
          { text: 'Fiatc', link: '#' },
          { text: 'Axa', link: '#' },
          { text: 'Santa Lucía', link: '#' },
          { text: 'Divina Pastora', link: '#' },
        ],
      ],
    };
  },
};
</script>

<style scoped>

</style>
