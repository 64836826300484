<template>
  <BaseAllArticles :title="$t('Top of this week')">

    <div class="flex flex-wrap pt-14 pb-[12rem] hidden md:flex">
      <div
        v-for="(article, index) in articles"
        :key="index"
        class="w-1/3 pr-4 pl-4"
      >
        <ArticleCard :article="article" />
      </div>
    </div>

		<div class="hidden sm:block pt-14">
			<SliderComponent :arrayData=articles type="three"/>
		</div>

  </BaseAllArticles>
</template>

<script>

import ArticleCard from '@/components/blog/articleCard.vue';
import SliderComponent from '@/components/blog/slider/sliderComponent.vue';
import BaseAllArticles from '@/components/blog/articles/baseAllArticles.vue';

export default {
  name: 'TopThisWeek',
  props: {
    articles: {
      type: Array,
      required: true
    }
  },
  components: {
    ArticleCard,
    BaseAllArticles,
		SliderComponent
  }
}
</script>

<style scoped></style>
