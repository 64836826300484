<template>
  <div class="p-[1.6rem]">
    <h3 class="text-[color:var(--black)] text-[1.8rem] font-bold">{{ title }}</h3>
    <p class="max-w-[54.7rem] text-[#161616] text-[1.8rem] leading-[1.33] pt-[2.4rem]">{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: 'TextContent',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
