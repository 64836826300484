<template>
  <div class="w-[6.4rem] h-[6.4rem] sm:w-[10rem] sm:h-[10rem] rounded-full overflow-hidden">
    <img class="w-full h-full object-cover" :src="image" :alt="name" />
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
</style>
