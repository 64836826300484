<template>
  <BaseAllArticles :title="$t('All articles')" link="`/blogs/${category}`">

    <div class="pt-14 hidden sm:block">
      <div class="flex flex-wrap" v-for="(article, index) in articlesData" :key="index">
          <ArticleCard :article="article" class="mt-[1.2rem]" />
			</div>
		</div>

    <div class="pt-14 hidden md:block">
      <div class="flex flex-wrap" v-if="articlesData[0]">
        <div class="w-2/3 pr-4 pl-4" v-if="articlesData[0]">
          <ArticleCard :article="articlesData[0]" />
        </div>
        <div class="w-1/3 pr-4 pl-4" v-if="articlesData[1]">
          <ArticleCard :article="articlesData[1]" />
        </div>
      </div>

      <div class="flex flex-wrap pt-[1.6rem] flex" v-if="articlesData[2]">
        <div class="w-1/3 pr-4 pl-4" v-if="articlesData[2]">
          <ArticleCard :article="articlesData[2]" />
        </div>
        <div class="w-1/3 pr-4 pl-4" v-if="articlesData[3]">
          <ArticleCard :article="articlesData[3]" />
        </div>
        <div class="w-1/3 pr-4 pl-4" v-if="articlesData[4]">
          <ArticleCard :article="articlesData[4]" />
        </div>
      </div>
      <div class="flex flex-wrap flex pt-[1.6rem]" v-if="articlesData[5]">
        <div class="w-1/3 pr-4 pl-4" v-if="articlesData[5]">
          <ArticleCard :article="articlesData[5]" />
        </div>
        <div class="w-2/3 pr-4 pl-4" v-if="articlesData[6]">
          <ArticleCard :article="articlesData[6]" />
        </div>
      </div>
      <div class="flex flex-wrap flex pt-[1.6rem]" v-if="articlesData[7]">
        <div class="w-2/3 pr-4 pl-4" v-if="articlesData[7]">
          <ArticleCard :article="articlesData[7]" />
        </div>
        <div class="w-1/3 pr-4 pl-4" v-if="articlesData[8]">
          <ArticleCard :article="articlesData[8]" />
        </div>
      </div>
      <div class="flex flex-wrap pt-[1.6rem] flex" v-if="articlesData[9]">
        <div class="w-1/3 pr-4 pl-4" v-if="articlesData[9]">
          <ArticleCard :article="articlesData[9]" />
        </div>
        <div class="w-1/3 pr-4 pl-4" v-if="articlesData[10]">
          <ArticleCard :article="articlesData[10]" />
        </div>
        <div class="w-1/3 pr-4 pl-4" v-if="articlesData[11]">
          <ArticleCard :article="articlesData[11]" />
        </div>
      </div>
    </div>
  </BaseAllArticles>
</template>

<script>
import ArticleCard from '@/components/blog/articleCard.vue';
import BaseAllArticles from '@/components/blog/articles/baseAllArticles.vue';

export default {
  name: 'XAllArticles',
  props: {
    articlesData: {
      type: Array,
      required: true
    },
    category: {
      type: String,
      required: true
    },
  },
  components: {
    ArticleCard,
    BaseAllArticles
  },
  watch: {
    category(newVal) {
      console.log('Category changed:', newVal);
    }
  }
}
</script>

<style scoped>
.food_image_gap {
  --bs-gutter-x: 1.6rem;
  --bs-gutter-y: 1.6rem;
}
</style>
