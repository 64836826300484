<template>
  <main>

    <SearchResultSection />

    <SpecialistsAvailableSection :doctors="doctors" v-if="doctors"/>

  </main>

</template>

<script>
import { useStore } from "vuex";
import { defineComponent } from "vue";

import SearchResultSection from '../components/search/searchResultSection.vue';
import SpecialistsAvailableSection from '../components/search/specialistsAvailableSection.vue';
import axios from 'axios';

export default defineComponent({
  name: "SearchView",
  components: {
		SearchResultSection,
		SpecialistsAvailableSection
  },
  async created() {
    this.store = useStore();
  },
  mounted() {
		this.search();
  },
  data() {
    return {
      doctors: null
    };
  },
  methods: {
		async search() {
			try {
        const id = this.$route.params.id;
				var doctors = await axios.get('https://api.fernandosilva.es/api/medkus/doctors');
				this.doctors = doctors.data
			} catch (error) {
				console.error('Error fetching featured doctors:', error);
			}
		}
  },
});
</script>
