<template>
  <div class="fixed inset-0" id="mapModal">
    <div class="modal-content modal-backdrop rounded-lg shadow-lg w-full">

      <div class="relative px-[1.8rem] py-[4.8rem] sm:px-[1.2rem]">
        <div class="md:container mx-auto">
          <div class="map_popup_background">
            <div class="flex flex-wrap map_popup_row">



              <div class="w-full px-8 md:w-1/2 sm:px-0">
                <div class="open_map_content_wrapper md:sticky-lg-top">
                  <div v-for="(doctor, index) in doctors" :key="index" :id="'doctor-' + index" class="mb-10 md:open_map_content">
                    <DoctorComponent
                      :doctor="doctor"
                      :showServiceTime="false"
                      :showPersonalizedService="true"
                      :showMapButton="true"
                      :showProfileButton="true"
                      class="backdrop-blur-[2px] px-[3.2rem] py-[2.4rem] sm:px-[1.6rem] sm:py-[1.2rem] rounded-3xl open_map_area sm:hidden"
                      @view-map="onDoctorViewMap(index)"
                      />
                    <DoctorComponent
                      :doctor="doctor"
                      class="backdrop-blur-[2px] sm:px-[1.6rem] sm:pt-[1.2rem] open_map_area hidden sm:block sm:rounded-t-3xl"
                      @view-map="onDoctorViewMap(index)"
                      />
                    <DoctorSchedule :doctor="doctor" isPopup="true" class="backdrop-blur-[2px] px-[3.2rem] pt-[2.4rem] pb-[2.4rem] sm:px-[1.6rem] sm:pb-[1.2rem] sm:pt-[0.0rem] md:rounded-3xl open_map_area mt-5 sm:mt-0 sm:w-[32rem] sm:rounded-b-3xl" />
                  </div>
                </div>
              </div>


              <div class="w-full px-8 md:w-1/2 sm:px-0">
                <div class="open_map_content map_main_wrap w-100 d-block sticky-lg-top">
                  <div class="relative h-[calc(100vh_-_12.4rem)] p-3 w-full">
                    <GoogleMaps :doctors="doctors" class="w-full h-full object-cover rounded-[2.4rem]" ref="map"/>
                    <CloseButton @click="closeMapModal" />
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DoctorComponent from '@/components/doctor/doctorComponent.vue';
import DoctorSchedule from '@/components/search/doctorSchedule.vue';
import CloseButton from '@/components/buttons/closeButton.vue';
import GoogleMaps from '@/components/doctor/googleMaps.vue';

export default {
  name: "MapModal",
  components: {
    DoctorComponent,
    DoctorSchedule,
    CloseButton,
    GoogleMaps
  },

  props: {
    doctors: {
      type: Array,
      required: true,
    },
  },

  methods: {
    onDoctorViewMap(id) {
      this.$refs.map.onMarkerClick(id);
    },

    closeMapModal() {
      this.$emit('close')
    }

  },

};
</script>

<style scoped>
#mapModal{
  z-index: 1055;
}

.sticky-lg-top{
  position: sticky;
  top: 0;
  z-index: 1020;
}

.map_popup_row {
  --bs-gutter-x: 4.8rem;
}

.open_map_area {
  background: #fff;
}

.open_map_content {
  @apply border backdrop-blur-md p-[1.6rem] rounded-[3.2rem] border-solid border-white;
  background: rgba(255, 255, 255, 0.49);
}

.open_map_content.sticky-lg-top {
  @apply top-[4.8rem];
}

</style>
