<template>
  <div>
    <div class="container mx-auto">

      <MainContent v-if="useAlternateLayout" paddingClass="p-[1.6rem]">
        <div class="px-0 py-[1.6rem]">
          <h3 class="text-[color:var(--black)] text-[1.8rem] font-bold">{{title}}</h3>
          <p class="text-[#161616] text-[1.8rem] leading-[1.33] pt-[2.4rem]">{{description}}</p>
        </div>
        <ImageContent :imageSrc="imageSrc" :imageAlt="imageAlt" />
      </MainContent>

      <MainContent v-else paddingClass="p-2">
        <div class="flex flex-col md:flex-row md:flex-wrap">
          <div v-if="reverseOrder" class="order-2 md:order-1 md:w-1/2 md:pr-4 pl-4">
            <ImageContent :imageSrc="imageSrc" :imageAlt="imageAlt" />
          </div>
          <div v-else class="md:w-1/2 md:pr-4 pl-4">
            <TextContent :title="title" :description="description" />
          </div>
          <div v-if="reverseOrder" class="order-1 md:order-2 md:w-1/2 md:pr-4 pl-4">
            <TextContent :title="title" :description="description" />
          </div>
          <div v-else class="md:w-1/2 md:pr-4 pl-4">
            <ImageContent :imageSrc="imageSrc" :imageAlt="imageAlt" />
          </div>
        </div>
      </MainContent>

    </div>
  </div>
</template>

<script>
import TextContent from './textContent.vue';
import ImageContent from './imageContent.vue';
import MainContent from '@/components/design/mainContent.vue';

export default {
  name: 'TextImageSection',
  components: {
    TextContent,
    ImageContent,
    MainContent
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    imageSrc: {
      type: String,
      required: true,
    },
    imageAlt: {
      type: String,
      required: true,
    },
    reverseOrder: {
      type: Boolean,
      default: false,
    },
    useAlternateLayout: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>

.punch_health_area {
  background: rgba(255, 255, 255, 0.49);
}

</style>
