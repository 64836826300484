import { Format } from "./formatHelper";

export function formatDateWithLocale(i18nLocale: string): string {

  const formatMapping = {
    day: 'd',
    month: 'm',
    year: 'Y',
    dayPeriod: '',
    era: '',
    hour: '',
    minute: '',
    second: '',
    timeZoneName: '',
    weekday: '',
    unknown: '',
    fractionalSecond: ''
  };

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  };

  const dateFormatter = new Intl.DateTimeFormat(i18nLocale, dateOptions);

  const dateFormat = dateFormatter.formatToParts(new Date())
    .map(part => part.type === 'literal' ? part.value : formatMapping[part.type])
    .join('');

  return dateFormat;
}


/// Date to string
export function formatDate(date: Date, format?: string) {
  if (date == undefined || date == null) {
    console.error("date in formatDate is null");
    return "";
  }

  if (!format || format == '') {
    format = "yyyy-MM-ddTHH:mm";
  }

  let result = format;

  if (format.indexOf("yyyy") != -1) {
    result = result.replace("yyyy", date.getFullYear().toString());
  }
  else if (format.indexOf("yy") != -1) {
    result = result.replace("yy", date.getFullYear().toString());
  }

  const month = pad0(date.getMonth() + 1);
  if (format.indexOf("MM") != -1) {
    result = result.replace("MM", month);
  }
  else if (format.indexOf("M") != -1) {
    result = result.replace("M", month);
  }

  const day = date.getDate();
  const strDay = pad0(day);

  if (format.indexOf("dd") != -1) {
    result = result.replace("dd", strDay);
  }
  else if (format.indexOf("d") != -1) {
    result = result.replace("d", strDay);
  }

  let hour = pad0(date.getHours());
  if (format.indexOf("HH") != -1) {
    result = result.replace("HH", hour);
  } else if (format.indexOf("H") != -1) {
    result = result.replace("H", hour);
  } else if (format.indexOf("h") != -1) {
    const dHours = date.getHours();
    if (dHours > 12) {
      hour = (dHours - 12).toString();
      result = result.replace("tt", "pm");
    } else {
      result = result.replace("tt", "am");
    }
    result = result.replace("h", hour);
  }
  if (format.indexOf("mm") != -1) {
    const min = pad0(date.getMinutes());
    result = result.replace("mm", min);
  }

  return result;
}

function pad0(input: number) {
  let inputStr = input.toString();
  if (input < 10) {
    inputStr = 0 + inputStr;
  }
  return inputStr;
}


export function setTimeToDate(date: Date, hour: string) {
  const [timePart, ampmPart] = hour.split(' ');
  let hours = timePart.split(/[:.]/).map(Number)[0];
  const minutes = timePart.split(/[:.]/).map(Number)[1];

  if (isNaN(hours) || isNaN(minutes)) {
    return date;
  }

  if (ampmPart) {
    const isPM = ampmPart.toLowerCase().includes('p');
    if (isPM && hours !== 12) {
      hours += 12;
    } else if (!isPM && hours === 12) {
      hours = 0;
    }
  }
  date.setHours(hours);
  date.setMinutes(minutes);

  return date;
}

export function formatTime(date: Date, navigator: any) {
  const userLanguage = navigator.language || navigator.userLanguage;
  const timeFormatter = new Intl.DateTimeFormat(userLanguage, {
    hour: "numeric",
    minute: "numeric",
  });

  return timeFormatter.format(date);
}

export function beautifyDate(date: string, showWeekDay?: boolean, showYear?: boolean) {
  if (Format.IsNull(date)) {
    return "";
  }
  const options: any = {
    //weekday: "long",
    // year: "numeric",
    month: "long",
    day: "numeric",
    hour: 'numeric',
    minute: 'numeric'
  };
  if (showWeekDay === true || showWeekDay === undefined) {
    options.weekday = "long";
  }
  if (showYear === true || showYear === undefined) {
    options.year = "numeric";
  }
  const str = capitalizeFirstLetter(new Date(date).toLocaleString(navigator.language, options));
  return str;
}

export function beautifyDateWithOutTime(date: string, showWeekDay?: boolean, showYear?: boolean) {
  if (Format.IsNull(date)) {
    return "";
  }
  const options: any = {
    month: "long",
    day: "numeric",
  };
  if (showWeekDay === true || showWeekDay === undefined) {
    options.weekday = "long";
  }
  if (showYear === true || showYear === undefined) {
    options.year = "numeric";
  }
  const str = capitalizeFirstLetter(new Date(date).toLocaleString(navigator.language, options));
  return str;
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}


export function beautifyShortDate(date: string) {
  const options: any = {
    month: "long",
    day: "numeric",
    hour: 'numeric',
    minute: 'numeric'
  };
  return new Date(date).toLocaleString(navigator.language, options);
}


export function advance30min(date: Date): Date {
  // Create a new date object based on the current date
  const advancedDate = new Date(date);

  // Approximate to the next 30 minutes
  const minutes = advancedDate.getMinutes();
  const next30Minutes = Math.ceil(minutes / 30) * 30;

  if (next30Minutes === 0) {
    advancedDate.setMinutes(30);
    advancedDate.setHours(advancedDate.getHours() + 1);
  } else {
    advancedDate.setMinutes(next30Minutes);
  }

  return advancedDate;
}

export function getDaysBetweenDates(date1: Date, date2: Date) {
  // Convert the dates to milliseconds
  const date1Ms = date1.getTime();
  const date2Ms = date2.getTime();

  // Calculate the difference in milliseconds
  const differenceMs = Math.abs(date2Ms - date1Ms);

  // Convert the difference to days
  const days = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

  return days;
}

export function getNextUserHourAndDay(schedule: any) {
  if (schedule == undefined || schedule == null) {
    console.error("user horus null");
    return null;
  }
  const currentDate = new Date();
  const currentDay = currentDate.getDay(); // Sunday: 0, Monday: 1, ..., Saturday: 6
  const currentHour = currentDate.getHours();

  let nextHour = null;
  let userTime = '';
  nextHour = { day: currentDay, time: currentHour, userTime };//this is today default, if days and hours are before today, the get today as next day

  for (let i = 0; i < schedule.length; i++) {
    const { day, time } = schedule[i];

    if (day > currentDay || (day === currentDay && time > currentHour)) {
      nextHour = { day, time, userTime };
      break;
    }

  }

  const hours = String(nextHour.time).padStart(2, '0');
  const minutes = '00';
  userTime = `${hours}:${minutes}`;
  nextHour.userTime = userTime;

  return nextHour;
}

// Function to extract time from the date string
export function getTimeFromDateString(dateStr: string) {
  if (dateStr == null || dateStr == undefined || dateStr == "") {
    return null;
  }
  const date = new Date(dateStr);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Format the time as "hh:mm"
  return `${formatNumber(hours)}:${formatNumber(minutes)}`;
}

// Helper function to format numbers as two digits
function formatNumber(number: number) {
  return number.toString().padStart(2, "0");
}

export function addMinutes(date: Date, mins: number) {
  return dateAdd(date, 'minute', mins);
}


export function dateAdd(date: Date, interval: string, units: number) {
  if (date == null) return;

  const ret = new Date(date); //don't change original date
  const checkRollover = function () { if (ret.getDate() != date.getDate()) ret.setDate(0); };
  switch (String(interval).toLowerCase()) {
    case 'year': ret.setFullYear(ret.getFullYear() + units); checkRollover(); break;
    case 'quarter': ret.setMonth(ret.getMonth() + 3 * units); checkRollover(); break;
    case 'month': ret.setMonth(ret.getMonth() + units); checkRollover(); break;
    case 'week': ret.setDate(ret.getDate() + 7 * units); break;
    case 'day': ret.setDate(ret.getDate() + units); break;
    case 'hour': ret.setTime(ret.getTime() + units * 3600000); break;
    case 'minute': ret.setTime(ret.getTime() + units * 60000); break;
    case 'second': ret.setTime(ret.getTime() + units * 1000); break;
    default: return null;
  }
  return ret;
}