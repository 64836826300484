<template>

  <MarketHeader
    selectedHeader="defaultHeader"
    />

  <main>
    <div class="next_appointments_sidebar">
      <div class="container mx-auto sm:px-6">
        <div class="md:flex appointments_gap">
          <div class="md:w-1/4 md:px-6">
					<AccountSidebar />
          </div>
          <div class="w-full md:w-3/4 md:px-6">
            <div class="tab-content pb_25">
              <div class="tab-pane opacity-0 opacity-100 block active" id="tab1" role="tabpanel">
                <router-view></router-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

	<BackGround/>

</template>

<script lang="ts">

import BackGround from '@/components/design/background.vue';
import { defineComponent } from "vue";
import MarketHeader from '@/components/header/header.vue';
import AccountSidebar from '@/components/account/accountSidebar.vue';

export default defineComponent({
  name: "AccountLayout",
  components: {
    MarketHeader,
    AccountSidebar,
		BackGround
  }
});
</script>

<style scoped>

@import "../assets/css/helper.css";
@import "../assets/css/style.css";
@import "../assets/css/responsive.css";

.appointments_gap {
	--bs-gutter-x: 3.2rem;
}



</style>
