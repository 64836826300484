<template>
  <ImageButtonCheck
    :imgSrc="require('@/assets/images/online.svg')"
    :text="$t('Online')"
  />
</template>

<script>

import { defineComponent } from 'vue';
import ImageButtonCheck from '@/components/buttons/imageButtonCheck.vue';


export default defineComponent({
  name: 'OnlinenButton',
  components: {
    ImageButtonCheck,
  },
});
</script>

<style scoped></style>
