<template>
  <a href="#" class="w-[3.2rem] h-[3.2rem] p-0 bg-[#222] text-white text-[1.6rem] font-semibold flex items-center justify-center rounded-full border border-white absolute top-[1.6rem] right-[1.6rem]">
    x
  </a>
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CloseButton',
  props: {

  }
});
</script>

<style scoped>

</style>
