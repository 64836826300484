<template>
        <footer>
            <div class="container mx-auto sm:px-4">
                <div class="bg-white border mb-24 px-[3.2rem] py-[2.3rem] rounded-[1.2rem] border-white">
                    <img class="w-[24.7rem]" src="@/assets/images/footer-logo.png" alt="" />
                    <div class="md:flex md:flex-wrap md:pt-12">
                        <div class="md:w-1/5 pr-4 pl-4 pr-4 pl-4">
                            <div class="footer_item">
                                <ul>
                                    <li><a href="#">About us</a></li>
                                    <li><a href="#">Contact</a></li>
                                    <li><a href="#">Privacy policy</a></li>
                                    <li><a href="#">Terms & conditions</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="md:w-1/5 pr-4 pl-4 pr-4 pl-4">
                            <div class="footer_item">
                                <ul>
                                    <li><a href="#">Search a Professional</a></li>
                                    <li><a href="#">For Professionals</a></li>
                                    <li><a href="#">Articles</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="md:w-2/5 pr-4 pl-4 pr-4 pl-4">
                            <div class="footer_item">
                                <ul>
                                    <li><a href="#">Facebook</a></li>
                                    <li><a href="#">Instagram</a></li>
                                    <li><a href="#">Twitter / X</a></li>
                                    <li><a href="#">LinkedIn</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="md:w-1/5 pr-4 pl-4 self-end sm:pt-[6.4rem]">
                            <div>
                                <p class="text-[1.2rem] leading-[1.7]">© 2020 Medkus. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
</template>

<script>

import { useStore } from "vuex";
import { defineComponent } from "vue";

export default defineComponent({
	name: 'MarketFooter',
});
</script>


<style scoped>

.footer_item {
	@apply sm:pt-[3.2rem];
}

.footer_item ul li:not(:last-child) {
  @apply pb-[1.1rem];
}
.footer_item ul li a {
  @apply text-[1.2rem] leading-[1.7] hover:opacity-60;
}

</style>
