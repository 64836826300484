<template>

    <h3 class="text-black md:text-[1.6rem] sm:text-[1.6rem] font-medium">Experiencia</h3>

    <div class="mt-[2.4rem]">
      <TextList
        :list="aboutMe"
        title="Sobre mi"
      />
    </div>

    <div class="mt-[2.4rem]">
      <TextList
        :list="education"
        :title="$t('Training')"
      />
    </div>
    
</template>

<script>

import TextList  from '@/components/form/textList.vue';

export default {
  name: 'WorkExperience',
  components: {
    TextList 
  },
  props: {
    aboutMe: {
      type: Array,
      required: true,
      default: () => [],
    },
    education: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
}
</script>

<style scoped>



</style>
