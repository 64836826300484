import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b71e649"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "container mx-auto sm:px-4 pt-[3.9rem]"
}
const _hoisted_2 = { class: "md:flex md:flex-wrap -mx-8" }
const _hoisted_3 = { class: "md:w-1/2 px-12" }
const _hoisted_4 = { class: "mt-[2.4rem]" }
const _hoisted_5 = { class: "md:w-1/2 hidden md:block px-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MarketHeader = _resolveComponent("MarketHeader")!
  const _component_AppointmentTitle = _resolveComponent("AppointmentTitle")!
  const _component_DoctorComponent = _resolveComponent("DoctorComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MainContent = _resolveComponent("MainContent")!
  const _component_BackGround = _resolveComponent("BackGround")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MarketHeader, { selectedHeader: _ctx.useHeader }, null, 8, ["selectedHeader"]),
    (_ctx.doctor)
      ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_MainContent, { paddingClass: "py-6 px-9" }, {
                default: _withCtx(() => [
                  _createVNode(_component_AppointmentTitle),
                  _createVNode(_component_DoctorComponent, {
                    class: "block md:hidden sm:border-b-white sm:border-b sm:border-solid",
                    doctor: _ctx.doctor,
                    showServiceTime: true,
                    showDoctorAddress: false,
                    showPaymentMethod: false,
                    showPersonalizedService: false,
                    showFirstConsultation: false
                  }, null, 8, ["doctor"]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_router_view, { onValidation: _ctx.handleValidation }, null, 8, ["onValidation"])
                  ])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_MainContent, { paddingClass: "py-6 px-9" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DoctorComponent, {
                    doctor: _ctx.doctor,
                    showServiceTime: true
                  }, null, 8, ["doctor"])
                ]),
                _: 1
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_BackGround)
  ], 64))
}