<template>

		<BaseAccount :title="$t('Account Settings')">

    <div class="appointment_account_settings pr_20" v-if="account">
      <div class="flex flex-wrap ">
        <div class="md:w-3/5 pr-4 pl-4">
          <div class="login_from mt_32">
            <div class="pb-[2.4rem] p-0">
              <InputField
                v-model="account.first_name"
                :label="$t('Name *')"
                required
                ref="nameField"
                inputClass="input mt-4"
              />
            </div>
            <div class="pb-[2.4rem] p-0">
              <InputField
                v-model="account.last_name"
                :label="$t('Last Name')"
                inputClass="input mt-4"
              />
            </div>
            <div class="pb-[2.4rem] p-0">
              <InputField
                v-model="account.age"
                :label="$t('Age')"
                inputClass="input mt-4"

              />
            </div>
            <div class="pb-[2.4rem] p-0">
							<label class="text-black font-bold">
								{{$t('Contact information *')}}
							</label>
              <div class="flex flex-nowrap gap-[0_1rem]">

								<CountryCode/>

                <InputField class="w-full"
                  v-model="account.phone"
                  required
                  ref="phoneField"
                  inputClass="input mt-4"
                />

              </div>
            </div>
            <div class="pb-[2.4rem] p-0">
              <InputField
                v-model="account.email"
                :label="$t('Email')"
                inputClass="input mt-4"
								:infoInInput="$t('Cambiar Email')"
              />
            </div>
            <CustomButton
              :label="$t('Continue')"
              @click="validateForm"
            />
          </div>
        </div>
      </div>
    </div>

  </BaseAccount>

</template>

<script>

import { defineComponent } from "vue";
import InputField from '@/components/input/inputField.vue';
import CustomButton from '@/components/buttons/customButton.vue';
import BaseAccount from '@/components/account/baseAccount.vue';
import CountryCode from '@/components/input/countryCode.vue';
import axios from 'axios';

export default defineComponent({
  name: "AccountSettingsView",
  components: {
    InputField,
    CustomButton,
		BaseAccount,
		CountryCode
  },
  data() {
    return {
      account: null
    };
  },
	mounted() {
		this.loadAppointments();
	},
  methods: {
    validateForm() {
      this.$refs.nameField.updateValue({ target: { value: this.account.first_name } });
      this.$refs.phoneField.updateValue({ target: { value: this.account.phone } });

      const isNameInvalid = this.$refs.nameField.isInvalid;
      const isPhoneInvalid = this.$refs.phoneField.isInvalid;
    },
		async loadAppointments() {
			try {
        const id = this.$route.params.id;
				var account = await axios.get('https://api.fernandosilva.es/api/medkus/account/details');
				this.account = account.data
			} catch (error) {
				console.error('Error fetching featured appointments:', error);
			}
		}
  }
});
</script>
