<template>

	<SliderOne v-if="type === 'one'" :posts="arrayData" />
	<SliderTwo v-if="type === 'two'" :posts="arrayData" />
	<SliderThree v-if="type === 'three'" :posts="arrayData" />


</template>

<script>

import { defineComponent, onMounted } from 'vue';
import SliderOne from '@/components/blog/slider/sliderOne.vue';
import SliderTwo from '@/components/blog/slider/sliderTwo.vue';
import SliderThree from '@/components/blog/slider/sliderThree.vue';

export default {
  name: 'SliderComponent',
  components:{
		SliderOne,
		SliderTwo,
		SliderThree
  },
  props: {
    arrayData: {
      type: Array,
      required: true
    },
		type: {
			type: String,
			default: "one"
		}
  },
}
</script>

<style scoped>


.related_gap {
	--bs-gutter-x: 3.5rem;
}


.relatedFoodSlider .our_blog_card {
	@apply w-full m-0;
}

.owl-item.active .our_blog_card {
  @apply m-0;
}
.owl-item.active + .owl-item .our_blog_card {
  @apply ml-[3.2rem];
}
.center .our_blog_card {
  @apply w-full;
}

</style>
