<template>
  <div class="container mx-auto sm:px-4">
    <div class="relative">
      <ImageContent :imageSrc="bannerImage" :imageAlt="imageAlt" />
      <div
        class="bg-white-50 absolute sm:w-[94%] p-[1.6rem] left-4 bottom-4 md:w-[47.2rem] sm:p-[1.6rem] sm:bottom-[2.2rem] rounded-xl border border-white"
      >
        <h4 class="text-white text-[2.4rem] font-semibold leading-none mb-4">
          {{title}}
        </h4>

        <DoctorImage
          :doctorImage="doctorImage"
          :doctorName="doctorName"
          additionalClass="text-[10px] text-white"
        />
      </div>
    </div>
  </div>
</template>

<script>

import DoctorImage from '@/components/blog/articles/doctorImage.vue';
import ImageContent from '@/components/blog/textImageSection/imageContent.vue';

export default {
  name: 'ArticlesHeroSection',
  components: {
    DoctorImage,
    ImageContent
  },
  props: {
    category: {
      type: String,
      required: true
    },
    categorySlug:{
        type: String,
        required: true
    },
    title: {
      type: String,
      required: true
    },
    bannerImage: {
      type: String,
      required: true
    },
    doctorImage: {
      type: String,
      required: true
    },
    doctorName: {
      type: String,
      required: true
    },
    postName: {
        type: String,
        require: true
    }
  },
}
</script>

<style scoped>
.background_content {
  background: rgba(185, 185, 185, 0.5);
}
</style>
