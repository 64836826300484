<template>
  <div class="background">
    <CookiesPrivacy />
    <router-view />
    <GlobalError />
    <Loader />
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue";
import CookiesPrivacy from "@/components/cookies/cookiesPrivacy.vue";
import Loader from "@/components/loader/loader.vue";
import GlobalError from "@/components/globalError/GlobalError.vue";

export default defineComponent({
  name: "App",
  components: {
    CookiesPrivacy,
    Loader,
    GlobalError,
  },
});
</script>

