<template>
  <div>
<img class="w-full rounded-[2.4rem] h-[36rem] object-cover " :src="imageSrc" :alt="imageAlt" />
  </div>
</template>

<script>
export default {
  name: 'ImageContent',
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
    imageAlt: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
