<template>
  <main>
      <InputField class="pb-[2.4rem] pt-[2.4rem]"
        v-model="email"
        :label="$t('Email *')"
        :placeholder="$t('Email')"
        required
				customInputClass="mt-4"
        ref="emailField"
      />

      <InputField class="pb-[2.4rem]"
        v-model="password"
        :label="$t('Password *')"
        :placeholder="$t('Password')"
				customInputClass="mt-4"
        inputType="password"
        required
        ref="passwordField"
      />

    <CustomButton
      :label="$t('Login')"
      @click="validateForm"
    />

    <div class="pt-[2.4rem]">
      <p class="text-black text-[1.4rem] md:text-[1.8rem]">
        {{$t('Do you not have an account yet?')}}
        <router-link to="/register" class="underline">
          {{$t('Sign up here')}}
        </router-link>
      </p>
    </div>

  </main>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import { defineComponent } from "vue";
import InputField from '@/components/input/inputField.vue';
import CustomButton from '@/components/buttons/customButton.vue';

export default defineComponent({
  name: "LoginView",
  components: {
    CustomButton,
    InputField
  },
  async created() {
    this.store = useStore();
  },
  data() {
    return {
      email: '',
      password: ''
    };
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  methods: {
    validateForm() {
      this.$refs.emailField.updateValue({ target: { value: this.email } });
      this.$refs.passwordField.updateValue({ target: { value: this.password } });

      const isEmailInvalid = this.$refs.emailField.isInvalid;
      const isPasswordInvalid = this.$refs.passwordField.isInvalid;

      if (!isEmailInvalid || !isPasswordInvalid) {
        this.router.push('/account/settings');
      }
    }
  }
});
</script>
