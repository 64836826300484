<template>
  <main>
    <div class="flex flex-wrap ">
      <div class="md:w-1/2 md:pr-4 w-full">
          <InputField
            class="pb-[2.4rem]"
            v-model="name"
            :label="$t('Name')"
            :required="true"
            ref="name"
            inputClass="input mt-4"
          />
      </div>

      <div class="md:w-1/2 w-full">
          <InputField
            class="pb-[2.4rem]"
            v-model="lastName"
            :label="$t('Last Name')"
            :required="true"
            ref="lastName"
            inputClass="input mt-4"
          />
      </div>
    </div>

    <div class="pb-[2.4rem] w-full">
      <label class="text-black font-bold">
        {{$t('Contact information *')}}
      </label>
      <div class="flex flex-nowrap gap-[0_1rem]">
				<CountryCode></CountryCode>
        <InputField class="w-full"
          v-model="phone"
          :required="true"
          @input="validate"
          ref="phone"
          inputClass="input mt-4"
        />
      </div>
    </div>

    <p class="pb_24">
      {{$t('*You will receive a code at this number to confirm the appointment and a reminder before the visit.')}}
    </p>

    <div class="pb-[2.4rem]">
      <TextareaField
        v-model="comentario"
        :placeholder="$t('You can include any comment for the specialist.')"
        :label="$t('Comment for the specialist (optional)')"
        textareaClass="textarea mt-4"
      />
    </div>

    <p class="pb_24">
      {{$t('You will receive a code at this number to confirm the appointment and a reminder before the visit')}}
    </p>
    <div class="pb_24">
      <InputField
        inputType="checkbox"
        :label="$t('* I give my consent for Medkus to process my health data in order to use the services. Learn more')"
        v-model="consent"
        :required="true"
        @change="validate"
        ref="consentField"
      />
    </div>
    <div class="font-jost">
      <p class="text-black leading-[1.71]">
        {{$t('When booking an appointment, you agree to our')}}
        <a href="#" class="underline">
          {{$t('terms and conditions')}}
        </a>
        {{$t('and confirm that you understand our')}}
        <a href="#" class="underline">
          {{$t('privacy policy.')}}
        </a>
      </p>
    </div>
  </main>

	<CustomButton class="mt-10" :label="$t('Continue')" @click="goToNextStep" />

</template>

<script>
import { defineComponent } from "vue";
import CustomButton from '@/components/buttons/customButton.vue';
import InputField from '@/components/input/inputField.vue';
import CountryCode from '@/components/input/countryCode.vue';
import TextareaField from '@/components/input/textareaField.vue';

export default defineComponent({
  name: "CheckoutStepTwoView",
  components: {
    CustomButton,
    InputField,
    TextareaField,
		CountryCode
  },
  data() {
    return {
      phone: "",
      name: "",
      lastName: "",
      comentario: "",
      consent: false,
      isPhoneInvalid: false,
      isConsentInvalid: false,
    };
  },
  methods: {
    validate() {
      this.isPhoneInvalid = this.phone.trim() === '';
      this.isConsentInvalid = !this.consent;

      var isValid = !this.isPhoneInvalid && !this.isConsentInvalid

      this.$refs.name.updateValue({ target: { value: this.name } });
      this.$refs.lastName.updateValue({ target: { value: this.lastName } });
      this.$refs.phone.updateValue({ target: { value: this.phone } });
      this.$refs.consentField.updateValue({ target: { checked: this.consent } });

      this.$emit('validation', isValid);
    },
    goToNextStep() {
      this.validate();
      if (!this.showCheckboxError && this.specialty && this.insurance) {
        const { date, hour, idDoctor, idAddress } = this.$route.params;
        this.$router.push({
          name: 'CheckoutStepTwo',
          params: {
            date,
            hour,
            idDoctor,
            idAddress,
          }
        });
      }
    }
  }
});
</script>

<style scoped>

</style>
