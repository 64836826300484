import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b960107"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MarketHeader = _resolveComponent("MarketHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MarketFooter = _resolveComponent("MarketFooter")!
  const _component_BackGround = _resolveComponent("BackGround")!
  const _component_BackToTop = _resolveComponent("BackToTop")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MarketHeader, { selectedHeader: _ctx.useHeader }, null, 8, ["selectedHeader"]),
    _createElementVNode("main", null, [
      _createVNode(_component_router_view)
    ]),
    (_ctx.useFooter)
      ? (_openBlock(), _createBlock(_component_MarketFooter, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_BackGround),
    _createVNode(_component_BackToTop)
  ]))
}