<template>
		<div class="bg-white mt-4 w-[9.1rem] shrink-0 px-[1.6rem] py-[1.2rem] rounded-[10rem]">
			+34
		</div>
</template>

<script>
export default {
  name: 'CountryCode',
}
</script>
