<template>

    <MainContent v-if="homeDesign" paddingClass="flex flex-col items-start gap-4 pt-[1.6rem] pb-[2.4rem] px-[2.4rem]">
      <div class="flex items-center justify-center gap-3">
        <DoctorImage :image="doctor.image" :name="doctor.name" />
        <h4 class="text-black text-[1.6rem]">{{ doctor.name }}</h4>
      </div>
      <p class="text-black font-bold">{{ doctor.specialty }}</p>
      <router-link :to="'/profile/' + doctor.id" class="view_profile text-black text-[1.6rem] underline hover:opacity-60">
        {{ $t('View profile') }}
      </router-link>
    </MainContent>

    <div v-else class="flex items-center gap-[1.2rem]">
      <DoctorImage :image="doctor.image" :name="doctor.name" />
      <div class="flex flex-col justify-center items-start gap-[0.8rem]">

        <p class="text-black text-[16px]">{{ doctor.name }}</p>
        <h3 class="text-black font-bold text-[1.4rem]">{{ doctor.specialty }}, {{ doctor.location }}</h3>

        <DoctorRating :reviews="doctor.reviews" />

      </div>
    </div>

</template>

<script>

import MainContent from '@/components/design/mainContent.vue';
import DoctorImage from '@/components/doctor/doctorImage.vue';
import DoctorRating from '@/components/doctor/doctorRating.vue';

export default {
  name: 'DoctorInformation',
  components: {
    MainContent,
    DoctorImage,
    DoctorRating
  },
  props: {
    doctor: {
      type: Object,
      required: true,
      default: () => ({
        image: '',
        name: '',
        specialty: '',
        location: '',
        rating: 0
      })
    },
    homeDesign: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
</style>
