<template>
	<BaseAccount
		:title="$t('Favorite Specialists')"
		:appointments=appointments
	>
		<template v-slot:doctorSlot="doctorProps">
      <DoctorComponent
        :doctor="doctorProps.doctor"
        :showServiceTime="true"
        :showPersonalizedService="false"
        :showMapButton="true"
        :showProfileButton="true"
        @view-map="handleViewMap"
      />
		</template>

	</BaseAccount>

  <MapModal
    v-if="isMapModalVisible"
    :doctors="selectedDoctor"
    @close="closeCancelModal"
  />

</template>


<script>
import { defineComponent } from "vue";
import DoctorComponent from '@/components/doctor/doctorComponent.vue';
import MapModal from '@/components/search/mapModal.vue';
import BaseAccount from '@/components/account/baseAccount.vue';
import axios from 'axios';

export default defineComponent({
  name: "AccountSettingsView",
  components: {
    DoctorComponent,
    MapModal,
		BaseAccount
  },
  data() {
    return {
      appointments: null,
      selectedDoctor: [],
      isMapModalVisible: false
    };
  },
	mounted() {
		this.loadAppointments();
	},
  methods: {
    handleViewMap(doctor) {
      this.selectedDoctor = [doctor];
      this.isMapModalVisible = true
    },
    closeCancelModal() {
      this.isMapModalVisible = false;
    },
		async loadAppointments() {
			try {
        const id = this.$route.params.id;
				var appointments = await axios.get('https://api.fernandosilva.es/api/medkus/account/favorites/doctors');
				this.appointments = appointments.data
			} catch (error) {
				console.error('Error fetching featured appointments:', error);
			}
		}
  }
});
</script>

<style scoped></style>
