import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-248703a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "next_appointments_sidebar" }
const _hoisted_2 = { class: "container mx-auto sm:px-6" }
const _hoisted_3 = { class: "md:flex appointments_gap" }
const _hoisted_4 = { class: "md:w-1/4 md:px-6" }
const _hoisted_5 = { class: "w-full md:w-3/4 md:px-6" }
const _hoisted_6 = { class: "tab-content pb_25" }
const _hoisted_7 = {
  class: "tab-pane opacity-0 opacity-100 block active",
  id: "tab1",
  role: "tabpanel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MarketHeader = _resolveComponent("MarketHeader")!
  const _component_AccountSidebar = _resolveComponent("AccountSidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_BackGround = _resolveComponent("BackGround")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MarketHeader, { selectedHeader: "defaultHeader" }),
    _createElementVNode("main", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_AccountSidebar)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_router_view)
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_BackGround)
  ], 64))
}