<template>
  <BaseAllArticles class="mt-24" :title="categoryTitle" link="categoryPath">

		<div class="hidden sm:block pt-14">
			<SliderComponent :arrayData=posts type="three"/>
		</div>

    <div class="pt-14 hidden md:block">
      <div v-if="isEven" class="flex flex-wrap">
        <div
          v-for="(item, index) in posts"
          :key="index"
          class="w-1/2 pr-4 pl-4"
        >
          <ArticleCard class="mt-[1.6rem]" :article="item" />
        </div>
      </div>


      <div v-else class="flex flex-wrap">
        <div class="w-1/2 pr-4 pl-4">
          <ArticleCard :article="posts[0]" />
        </div>
        <div class="w-1/2 pr-4 pl-4">
          <ArticleCard :article="posts[1]" />
          <ArticleCard class="mt-[1.6rem]" :article="posts[2]" />
        </div>
      </div>
    </div>
  </BaseAllArticles>
</template>

<script>

import ArticleCard from "@/components/blog/articleCard.vue";
import BaseAllArticles from '@/components/blog/articles/baseAllArticles.vue';
import SliderComponent from '@/components/blog/slider/sliderComponent.vue';

export default {
  name: 'CategoryPosts',
  components: {
    ArticleCard,
    BaseAllArticles,
		SliderComponent
  },
  props: {
    posts: {
      type: Array,
      required: true,
      default: () => []
    },
    categoryTitle: {
      type: String,
      required: true,
      default: 'Category'
    }
  },
  computed: {
    categoryPath() {
      return `/blogs/${this.slugify(this.categoryTitle)}`;
    },
    isEven() {
      return this.posts.length % 2 === 0;
    }
  },
  methods: {
    slugify(text) {
      return text
        .toString()
        .toLowerCase()
    }
  }
}
</script>

<style scoped>

</style>
