<template>
  <div>
    <div class="text-black text-[1.6rem]">
      <h3>{{ reviews.length }} {{$t('reviews')}}</h3>
      <DoctorRating :reviews="reviews" />
    </div>
    <div v-for="review in reviews" :key="review.id" class="items-start w-full flex gap-6 py-[2.2rem]">

			<Review
				:review=review
			>
			</Review>

    </div>
  </div>
</template>

<script>

import Review from '@/components/doctor/review.vue';
import DoctorRating from '@/components/doctor/doctorRating.vue';

export default {
  name: 'ReviewsComponent',
	components: {
		Review,
    DoctorRating
	},
  props: {
    reviews: {
      type: Array,
      required: true,
      default: () => [],
    },
    averageRating: {
      type: Number,
      required: true,
      default: 5.0,
    },
  },
}
</script>

<style scoped>

.rating_text {
	font-family: Manrope;
}

</style>
