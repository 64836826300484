<template>
  <div class="md:pt-48 sm:pt-[5.0rem]" v-if="reviews">
    <div class="container mx-auto sm:px-4">
      <MainContent class="p-[2.4rem]">
        <div class="text-black text-[2rem] font-semibold text-center">
          <h4>
            {{$t('Our clients')}}
          </h4>
          <p
            class="text-[1.4rem] text-black font-semibold text-center pt-[1.6rem]"
          >
            {{$t('More than 4000 clients are already enjoying the new medicine.')}}
          </p>
        </div>
        <div class="pt-[2.4rem]">
          <div class="text-left">
              <carousel :items-to-show="itemsToShow" :breakpoints="breakpoints" :snapAlign="snapAlign">
                <slide class="item" v-for="(client, slide) in reviews" :key="slide">
                  <SubContent paddingClass="p-[2.2rem] flex gap-6 items-center">
                    <Review :review="client"></Review>
                  </SubContent>
                </slide>
              </carousel>
          </div>
        </div>
      </MainContent>
    </div>
  </div>
</template>

<script>

import { defineComponent, onMounted } from 'vue';
import MainContent from '@/components/design/mainContent.vue';
import SubContent from '@/components/design/subContent.vue';
import Review from '@/components/doctor/review.vue';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'
import axios from 'axios';

export default defineComponent({
  name: 'OurClientsSection',
  components: {
    MainContent,
    SubContent,
		Review,
    Carousel,
    Slide,

  },
  data() {
    return {
      breakpoints: {
        0: {
          itemsToShow: 0.8,
        },
        1024: {
          itemsToShow: 2.8,
          snapAlign: 'start',
        },
      },
      snapAlign: 'start',
      reviews: null
    }
  },
  mounted() {
		this.loadData();
  },
  methods: {
		async loadData() {
			try {
        const id = this.$route.params.id;
				var reviews = await axios.get('https://api.fernandosilva.es/api/medkus/doctors/reviews');
				this.reviews = reviews.data
			} catch (error) {
				console.error('Error fetching featured doctors:', error);
			}
		}
  },
});
</script>

<style scoped>

</style>
