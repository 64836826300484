<template>

  <div class="text-[#868686] font-semibold pb_24">
    <router-link to="/blogs">{{$t('All categories')}}/</router-link>
    <component :is="title ? 'router-link' : 'span'" :to="title ? `/blogs/${categorySlug}` : null">
      {{ category }}
    </component>
    <span v-if="title">/ {{ title }}</span>
  </div>
</template>

<script>
export default {
  name: 'ArticlesPagination',
  props: {
    categorySlug: {
      type: String,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>
span{
  @apply text-[color:var(--black)] font-semibold
}
</style>
