<template>
  <div class="w-full flex flex-col justify-center items-center gap-[1.2rem] self-stretch px-0 py-[2.4rem] border-t-white border-t border-solid">
    <a :href="signInLinks.apple.href" class="login_button">
      <img :src="signInLinks.apple.imgSrc" :alt="signInLinks.apple.alt">
      {{ $t('Sign in with Apple') }}
    </a>
    <a :href="signInLinks.facebook.href" class="login_button">
      <img :src="signInLinks.facebook.imgSrc" :alt="signInLinks.facebook.alt">
      {{ $t('Sign in with Facebook') }}
    </a>
    <a :href="signInLinks.google.href" class="login_button">
      <img :src="signInLinks.google.imgSrc" :alt="signInLinks.google.alt">
      {{ $t('Sign in with Google') }}
    </a>
  </div>
</template>

<script>

export default {

  data() {
    return {
      signInLinks: {
        apple: {
          href: '#',
          imgSrc: require('@/assets/images/apple-icon.svg'),
          alt: 'Apple'
        },
        facebook: {
          href: '#',
          imgSrc: require('@/assets/images/facebook-icon.svg'),
          alt: 'Facebook'
        },
        google: {
          href: '#',
          imgSrc: require('@/assets/images/social-icon.png'),
          alt: 'Google'
        }
      }
    };
  }
};
</script>

<style scoped>


.login_button {
	@apply flex justify-center items-center gap-4 self-stretch border backdrop-blur-md px-[1.6rem] py-[1.2rem] rounded-[10rem] border-solid border-white;
	background: #fff;
}
.login_button img {
	@apply w-[1.8rem];
}
.login_button span {
	@apply text-[color:var(--Gray-700,#344054)];
}

</style>
