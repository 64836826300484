<template>
  <template v-if="selectedHeader === 'defaultHeader'">
    <header class="bg-white-50 sticky z-[1024] w-full backdrop-blur-[6px] left-0 top-0">
      <div class="container mx-auto sm:px-4">
        <div class="px-0 py-[3.2rem]">
          <div class="flex flex-wrap items-center">
            <div class="w-1/2">
              <router-link :to="{ name: 'Home' }" class="w-full block md:block">
                <img class="w-[11.3rem]" src="@/assets/images/logo.svg" alt="" />
              </router-link>
            </div>
            <div class="w-1/2 text-end">
              <ul class="menu hidden md:block">
                <li><a href="#">About us</a></li>
                <li><a href="#">Doctors</a></li>
                <li><router-link :to="{ name: 'Login' }">Register/Login</router-link></li>
              </ul>
              <button @click="toggleHamburger" class="hamburger md:hidden">
                <img src="@/assets/images/Hamburger_MD.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  </template>

  <template v-else-if="selectedHeader === 'loginRegisterHeader'">
    <header class="bg-white-50 sticky z-[1024] w-full backdrop-blur-[6px] left-0 top-0">
      <div class="container mx-auto sm:px-4 px-0 py-[3.2rem]">
        <div class="flex items-center justify-center">
          <router-link :to="{ name: 'Home' }">
            <img class="w-[8.1rem]" src="@/assets/images/logo.svg" alt="" />
          </router-link>
        </div>
      </div>
    </header>
  </template>



  <template v-else-if="selectedHeader === 'searchHeader'">
    <header class="bg-white-50 sticky z-[1024] w-full backdrop-blur-[6px] left-0 top-0">
      <div class="container mx-auto sm:px-4">
        <div class="px-0 py-[1.6rem]">
          <div class="flex flex-wrap items-center">
            <div class="w-1/2 md:w-1/3 sm:order-1">
              <router-link :to="{ name: 'Home' }" class="block">
                <img class="w-[11.3rem]" src="@/assets/images/logo.svg" alt="Logo" />
              </router-link>
            </div>

						<div class="w-full md:w-1/3 flex md:flex-wrap justify-center mt-4 md:mt-0 sm:order-3">
							<div class="w-4/5 flex items-center gap-4">
								<input
									type="text"
									placeholder="Cardiologo . Barcelona"
									class="filter_info w-full p-2 border rounded-l-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
									/>
								<button class="p-2 bg-indigo-600 text-white rounded-r-lg search_button">
									<img src="@/assets/images/Search_Magnifying_Glass.svg" alt="Buscar" />
								</button>
							</div>

							<button
								class="filter_info inline-flex items-center text-black text-base gap-x-3 gap-y-0 p-6 hidden sm:flex"
                @click="toggleFilter"
								>
								<span>{{ $t('filters') }}</span>
								<img src="@/assets/images/filter-icon.svg" alt="Filtros" />
							</button>
						</div>


            <div class="w-1/2 md:w-1/3 flex justify-end sm:order-2">
              <ul class="menu hidden md:flex space-x-4">
                <li><a href="#">About us</a></li>
                <li><a href="#">Doctors</a></li>
                <li><router-link :to="{ name: 'Login' }">Register/Login</router-link></li>
              </ul>

              <button @click="toggleHamburger" class="hamburger md:hidden p-2">
                <img src="@/assets/images/Hamburger_MD.svg" alt="Menú" />
              </button>
            </div>

          </div>
        </div>
      </div>
    </header>
  </template>

  <template v-else-if="selectedHeader === 'checkoutHeader'">
    <header class="bg-white-50 sticky z-[1024] w-full backdrop-blur-[6px] left-0 top-0">
      <div class="container mx-auto sm:px-4 px-0 py-[3.2rem]">
        <div class="flex items-center justify-between">
          <div class="w-1/6 flex justify-start">
            <img @click="goBack" class="left_button_icon" src="@/assets/images/Left-button-icon.svg" alt="Left Button Icon" />
          </div>
          <div class="w-4/6 flex justify-center">
            <router-link :to="{ name: 'Home' }">
              <img class="w-[8.1rem]" src="@/assets/images/logo.svg" alt="Logo" />
            </router-link>
          </div>
          <div class="w-1/6 flex justify-end">
          </div>
        </div>
      </div>
    </header>
  </template>

  <HeaderHamburger :isOpen="isHamburgerClicked" />

</template>

<script>

import { defineComponent, PropType } from 'vue';
import HeaderHamburger from '@/components/header/headerHamburger.vue';
import EventBus from '@/helpers/eventBus';

export default defineComponent({
	name: 'MarketHeader',
  components: {
		HeaderHamburger
  },
	props: {
		selectedHeader: {
			type: String,
			required: true,
		}
	},
  data() {
    return {
      isHamburgerClicked: false,
    };
  },
	methods: {
		goBack() {
			this.$router.go(-1);
		},
    toggleHamburger() {
      this.isHamburgerClicked = !this.isHamburgerClicked;
    },
    toggleFilter() {
      EventBus.dispatchEvent(new CustomEvent('toggle-filter'));
    }
	},

});
</script>


<style scoped>

.left_button_icon{
	display: inline-block;
	border-radius: .6rem;
	transition: 300ms;
	cursor:pointer;
}

.left_button_icon:hover{
	background: #000;
}

.search_button{
	width: 4.8rem;
	height: 4.8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	padding: 0;
	border-radius: 10rem;
	border: 1px solid #FFF;
	background: #323232;
	backdrop-filter: blur(12px);
}

.filter_info{
	width: 36.2rem;
	height: 4.8rem;
	border-radius: 10rem;
	border: 1px solid #FFF;
	background: rgba(255, 255, 255, 0.90);
	box-shadow: 0px 4px 16px 0px rgba(190, 190, 190, 0.25);
	backdrop-filter: blur(12px);
}

</style>
