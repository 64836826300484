<template>
  <BaseModal :title="$t('Are you sure you want to cancel your appointment?')" @close-modal="emitCloseModal">
    <DoctorComponent
      :doctor="doctor"
      :showServiceTime="true"
      :showCancelAppointmentButton="true"
      @close-modal="emitCloseModal"
    />
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/popup/modal/baseModal.vue';
import DoctorComponent from '@/components/doctor/doctorComponent.vue';

export default {
  name: 'CancelPopup',
  components: {
    DoctorComponent,
    BaseModal
  },
  props: {
    doctor: {
      type: Object,
      required: true
    }
  },
  methods: {
    emitCloseModal() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>