<template>

      <div class="overflow-hidden">
        <div class="owl-carousel owl-theme blogSlider w-[50rem] h-[44rem] relative m-auto">
          <div class="item" v-for="(blog, index) in posts" :key="index">

            <SubContent paddingClass="our_blog_card md:w-[17rem] md:ml-auto md:mx-[3.2rem] md:my-0 md:p-[0.8rem]">
              <BlogCard :blog="blog" />
            </SubContent>
            
          </div>
        </div>
      </div>

      <div class="blog_slider_arrow ">
        <div>
          <p>
            {{$t('Next article')}}
          </p>
          <button class="arrow blogSlidePrev">
            <img src="@/assets/images/left_arrow.svg" alt="Left arrow" />
          </button>
        </div>
        <div>
          <p>
            {{$t('Previous article')}}
          </p>
          <button class="arrow blogSlideNext">
            <img src="@/assets/images/right_arrow.svg" alt="Right arrow" />
          </button>
        </div>
      </div>

</template>

<script>

import { defineComponent, onMounted } from 'vue';
import SubContent from '@/components/design/subContent.vue';
import BlogCard from '@/components/blog/post/blogCard.vue';

export default {
  name: 'SliderTwo',
  components:{
    SubContent,
    BlogCard
  },
  props: {
    posts: {
      type: Array,
      required: true
    }
  },
}
</script>

<style scoped>


.related_gap {
	--bs-gutter-x: 3.5rem;
}


.relatedFoodSlider .our_blog_card {
	@apply w-full m-0;
}

.owl-item.active .our_blog_card {
  @apply m-0;
}
.owl-item.active + .owl-item .our_blog_card {
  @apply ml-[3.2rem];
}
.center .our_blog_card {
  @apply w-full;
}

</style>
