<template>
  <div class="doctor_component">
    <DoctorInformation :doctor="doctor" />

    <div v-if="showServiceTime" class="flex flex-col items-start gap-[1.6rem] self-stretch px-0 py-[1.6rem]">
      <div v-if="showCalendar" class="flex items-center justify-start gap-4">
        <img class="w-[2.4rem]" src="@/assets/images/Calendar.svg" alt="" />
        <p class="text-black font-medium">{{ doctor.appointmentDate }}</p>
      </div>
      <div v-if="showDoctorAddress" class="flex items-center justify-start gap-4">
        <img class="w-[2.4rem]" src="@/assets/images/map-white.svg" alt="" />
        <p class="text-black font-medium">{{ doctor.mainAddress }}</p>
      </div>
      <div v-if="showPaymentMethod" class="flex items-center justify-start gap-4">
        <img class="w-[2.4rem]" src="@/assets/images/credit_card.svg" alt="" />
        <p class="text-black font-medium">{{ doctor.paymentMethod }}</p>
      </div>
    </div>

    <div v-if="showPersonalizedService" class="pt-[1.6rem] pb-[12.4rem] px-0">
      <p class="text-black text-[1.2rem] font-medium">{{$t('Personalized service.')}}</p>
      <ul class="service_list flex flex-col gap-[1.6rem] ml-8 px-0 py-[2.4rem] text-black text-[1.2rem]">
        <li>
          {{$t('Personalized service.')}}
        </li>
        <li>
          {{$t('Personalized service.')}}
        </li>
        <li>
          {{$t('We take care of your health.')}}
        </li>
      </ul>
    </div>

    <div class="px-0 py-[2.9rem]" v-if="showAboutMe">
      <p class="text-black md:text-[1.2rem] font-medium pb-[0.8rem] sm:text-[1.2rem]">Sobre mi</p>
      <div class="overflow-hidden text-black text-ellipsis whitespace-nowrap md:text-[1.2rem] font-medium leading-[1.33] sm:text-[1.2rem]">
        <p v-for="(item, index) in doctor.aboutMe" :key="index">{{ item }}</p>
      </div>
      <a href="#" class="text-black md:text-[1.2rem] block font-medium underline pt-[0.8rem] sm:text-[1.2rem]">Mostrar mas</a>
    </div>

    <div v-if="showFirstConsultation" class="flex items-center justify-between">
      <h4 class="text-black font-medium md:text-[14px] sm:text-[14px]">
        {{$t('First consultation')}}
      </h4>
      <p class="text-black font-bold md:text-[14px] sm:text-[14px]">€{{ doctor.consultationFee }}</p>
    </div>


    <div class="qualify_resize text-center pt_16">
      <div class="flex flex-wrap specialist_button_gap">
        <div class="view_profile_button w-full md:flex items-start gap-4">
          <button
            v-if="showMapButton"
            class="view_button w-full px-[1.6rem] py-[0.8rem] mb-4"
            @click.prevent="viewMapModal"
            >
            <span>
              {{$t('View on map')}}
            </span>
            <img src="@/assets/images/maps.svg" alt="" />
          </button>

          <button
            v-if="showScheduleButton"
            class="view_button w-full px-[1.6rem] py-[0.8rem] mb-4"
            @click.prevent="viewMapModal"
            >
            <span>
              {{$t('View schedules')}}
            </span>
          </button>

          <router-link
            v-if="showProfileButton"
            :to="`/profile/${doctor.id}`"
            class="view_button btn_black w-full"
            >
            {{$t('View profile')}}
          </router-link>
        </div>
      </div>
    </div>



    <div v-if="showCancelButton" class="text-center px-[3.2rem] py-[1.6rem] mt_24">

      <CustomButton
        isCancelPrimary="true"
        :label="$t('Cancel appointment')"
        @click.prevent="emitCancel"
      />
      
    </div>

    <div v-if="showReviewButton" class="text-center pt_16">
      <CustomButton class="h-[5.6rem] w-[28.9rem]"
        :label="$t('Calificar especialista')"
        @click.prevent="emitReview"
      />
    </div>

    <div v-if="showCancelAppointmentButton" class="view_profile_button mt_32 flex items-start gap-4">
      <CustomButton class="w-full h-[5.6rem]"
        :label="$t('Yes, cancel')"
        isCancel= "true"
      />    
      <CustomButton class="w-full h-[5.6rem] "
        :label="$t('Return')"
      />
    </div>
  </div>
</template>

<script>

import DoctorInformation from '@/components/doctor/doctorInformation.vue';
import CustomButton from '@/components/buttons/customButton.vue';

export default {
  name: 'DoctorComponent',
  components: {
    DoctorInformation,
    CustomButton
  },
  props: {
    doctor: {
      type: Object,
      required: false
    },
    showServiceTime: {
      type: Boolean,
      default: false
    },
    showCalendar: {
      type: Boolean,
      default: true
    },
    showFirstConsultation: {
      type: Boolean,
      default: true
    },
    showDoctorAddress: {
      type: Boolean,
      default: true
    },
    showPaymentMethod: {
      type: Boolean,
      default: true,
    },
    showPersonalizedService: {
      type: Boolean,
      default: false
    },
    showAboutMe: {
      type: Boolean,
      default: false
    },
    showMapButton: {
      type: Boolean,
      default: false
    },
    showScheduleButton: {
      type: Boolean,
      default: false
    },
    showProfileButton: {
      type: Boolean,
      default: false
    },
    showCancelButton: {
      type: Boolean,
      default: false
    },
    showReviewButton: {
      type: Boolean,
      default: false
    },
    showCancelAppointmentButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitCancel() {
      this.$emit('cancel-appointment', this.doctor);
    },
    emitReview() {
      this.$emit('review-appointment', this.doctor);
    },
    viewMapModal() {
      this.$emit('view-map', this.doctor);
    },
    emitCloseModal() {
      this.$emit('close-modal');
    }
  }
}
</script>

<style scoped>


</style>
