import enUS from "./en-US.json";
import esES from "./es-ES.json";
import ca from "./ca.json";

export enum Locales {
    enUS = 'en-US',
    esES = 'es-ES',
    ca = 'ca'
}

export const LOCALES = [
    { value: Locales.enUS, caption: 'English USA' },
    { value: Locales.esES, caption: 'Español' },
    { value: Locales.ca, caption: 'Català' }
];

export const messages = {
    [Locales.ca]: ca,
    [Locales.esES]: esES,
    [Locales.enUS]: enUS,
};

export const defaultLocale = Locales.enUS;

