<template>
  <div class="pb-[4.8rem]">
    <div class="container mx-auto sm:px-4">
      <div class="pt-[2.4rem] pb-[2.4rem] px-0 md:p-[2.4rem]">
        <h2 class="text-black text-[1.6rem] font-semibold">{{ doctors.length }} {{$t('available specialists')}}</h2>

        <a class="hidden md:block bg-white fixed z-[100] w-[29rem] h-[29rem] p-2 rounded-[2rem] right-0 bottom-0">
          <GoogleMaps :doctors="doctors" class="w-full h-full" ref="map"/>
          <button
            class="w-[21.8rem] text-center bg-white absolute -translate-x-2/4 px-[2.2rem] py-[1.1rem] rounded-[2.9rem] border border-white left-2/4 top-12"
            @click.prevent="openMapWithAllDoctors"
            >
            <h3 class="text-black text-[1.8rem]">Ampliar mapa</h3>
          </button>
        </a>

      </div>

      <div class="pt-[1.6rem] pb-[1.6rem] px-0 md:p-[1.6rem]" v-for="(doctor, index) in doctors" :key="index">
        <div class="bg-white-50 rounded-[2.4rem] p-[1.6rem] sm:p-[0.8rem] border border-white md:max-w-none max-w-full top-48">
          <div class="md:flex gap-4 md:pb-4">
            <div class="lg:w-1/2 bg-white backdrop-blur-[2px] rounded-[2.4rem] sm:px-[1.6rem] px-[3.2rem] py-[2.4rem] w-full">
              <DoctorComponent
                :doctor="doctor"
                :showServiceTime="false"
                :showPersonalizedService="true"
                :showMapButton="true"
                :showProfileButton="true"
                @view-map="handleViewMap"
                />
            </div>
            <div class="lg:w-1/2 bg-white h-full relative rounded-[2.9rem] border border-white w-full sm:px-[1.6rem] px-[3.2rem] py-[2.4rem] sm:mt-5 sm:w-128">
              <DoctorSchedule :doctor="doctor"/>
            </div>
          </div>
        </div>
      </div>

      <MapModal
        v-if="isMapModalVisible"
        :doctors="selectedDoctor"
        @close="closeMapModal"
        />

    </div>
  </div>
</template>

<script>
import DoctorSchedule from '@/components/search/doctorSchedule.vue';
import DoctorComponent from '@/components/doctor/doctorComponent.vue';
import MapModal from '@/components/search/mapModal.vue';
import GoogleMaps from '@/components/doctor/googleMaps.vue';

export default {
  name: 'SpecialistsAvailableSection',
  components: {
    DoctorComponent,
    DoctorSchedule,
    MapModal,
    GoogleMaps
  },
  props: {
    doctors: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedDoctor: [],
      isMapModalVisible: false
    }
  },
  methods: {
    handleViewMap(doctor) {
      this.selectedDoctor = [doctor];
      this.isMapModalVisible = true
    },
    openMapWithAllDoctors() {
      this.selectedDoctor = this.doctors;
      this.isMapModalVisible = true
    },
    closeMapModal() {
      this.isMapModalVisible = false;
    }
  }

}
</script>

<style scoped>

</style>
