<template>
  <a :href="articleLink" class="block relative">
    <img :src="article.image" :alt="article.alt" class="rounded-[2.4rem] sm:h-[512px] sm:w-full">
    <div class="background_color backdrop-blur-[5.5px] absolute w-full p-[2.4rem] rounded-[0px_0px_2.4rem_2.4rem] bottom-0">
      <h4 class="text-left text-white text-[2.4rem] font-semibold mb-4 pt-[2.4rem]">{{ article.title }}</h4>

      <DoctorImage 
        :doctorImage=article.authorImage 
        :doctorName=article.authorName 
      />

    </div>
  </a>
</template>

<script>

import DoctorImage from '@/components/blog/articles/doctorImage.vue';

export default {
  name: 'ArticleCard',
  components: {
    DoctorImage
  },
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  computed: {
    articleLink() {
      const categorySlug = this.slugify(this.article.category);
      const articleSlug = this.slugify(this.article.title);
      return `/blogs/${categorySlug}/${articleSlug}`;
    }
  },
  methods: {
    slugify(text) {
      return text.toString().toLowerCase().trim()
    }
  }
}
</script>

<style scoped>

.background_color {
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.24) 48%, #000 100%);
}

</style>
