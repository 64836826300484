<template>

          <carousel class="item center" :items-to-show="itemsToShow" :breakpoints="breakpoints" :snapAlign="snapAlign">
            <slide class="item" v-for="(blog, slide) in posts" :key="slide">
              <ArticleCard :article="blog" />
            </slide>
          </carousel>

</template>

<script>

import { defineComponent, onMounted } from 'vue';
import ArticleCard from '@/components/blog/articleCard.vue';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

export default {
  name: 'SliderThree',
  components:{
		ArticleCard,
    Carousel,
    Slide
  },
  props: {
    posts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      itemsToShow: 1,
      snapAlign: 'start',
      breakpoints: {
        0: {
          itemsToShow: 1,
        },
        1024: {
          itemsToShow: 3,
        },
      },
    }
  },
}
</script>

<style scoped>

.related_gap {
	--bs-gutter-x: 3.5rem;
}

.relatedFoodSlider .our_blog_card {
	@apply w-full m-0;
}

.owl-item.active + .owl-item .our_blog_card {
  @apply ml-[3.2rem];
}
.center .our_blog_card {
  @apply w-full;
}

.blogSlider .owl-stage-outer {
  @apply overflow-visible;
}

</style>
